import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1014.000000 764.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,764.000000) scale(0.100000,-0.100000)">
     

<path d="M0 3820 l0 -3820 5070 0 5070 0 0 3420 0 3420 -30 0 -30 0 0 80 0 80
-40 0 -40 0 0 40 0 40 -40 0 -40 0 0 80 0 80 -40 0 -40 0 0 120 c0 100 -2 120
-15 120 -11 0 -15 11 -15 40 0 22 -4 40 -10 40 -5 0 -10 18 -10 40 l0 40
-4895 0 -4895 0 0 -3820z m9440 3700 c0 -29 -4 -40 -15 -40 -11 0 -15 11 -15
40 0 29 4 40 15 40 11 0 15 -11 15 -40z m-4120 -910 c0 -5 -5 -10 -11 -10 -5
0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-66 -55 c-5 -11 -9 -12 -19 -2
-9 8 -10 14 -2 19 16 9 27 0 21 -17z m136 -16 c0 -5 -7 -9 -15 -9 -15 0 -20
12 -9 23 8 8 24 -1 24 -14z m130 7 c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25
9 15 25 12 25 -4z m-730 -11 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11
23 6 23 -9z m1240 -49 c-14 -14 -32 3 -17 18 9 10 14 11 19 2 4 -6 3 -15 -2
-20z m-1017 7 c3 -5 -1 -13 -8 -20 -10 -10 -14 -9 -18 1 -8 21 15 37 26 19z
m162 -2 c3 -6 -3 -15 -14 -20 -14 -8 -20 -7 -24 3 -9 24 24 38 38 17z m149 1
c7 -12 -7 -27 -25 -28 -14 -1 -17 9 -10 34 2 6 30 1 35 -6z m126 -32 c0 -5 -5
-12 -11 -16 -14 -8 -33 11 -25 25 7 11 36 4 36 -9z m-720 -16 c0 -18 -14 -18
-29 0 -9 11 -9 15 1 19 17 6 28 -1 28 -19z m1057 4 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-937 -14 c0 -17 -26 -19 -36 -3 -7 12 15 31 28 23
4 -3 8 -12 8 -20z m1023 8 c15 -17 15 -18 -8 -20 -24 -2 -34 16 -18 31 9 9 8
10 26 -11z m-1249 -7 c-4 -8 -10 -12 -15 -9 -14 8 -10 24 6 24 9 0 12 -6 9
-15z m-73 -10 c-1 -15 -24 -12 -29 3 -3 9 2 13 12 10 10 -1 17 -7 17 -13z
m1089 -11 c0 -16 -26 -19 -35 -4 -3 6 -4 14 0 20 8 13 35 1 35 -16z m310 5 c0
-5 -9 -9 -20 -9 -19 0 -26 11 -14 23 8 8 34 -3 34 -14z m-960 -3 c0 -11 -33
-36 -41 -31 -19 12 -9 35 16 35 14 0 25 -2 25 -4z m335 -27 c-3 -4 -13 -5 -22
-2 -11 4 -13 8 -5 11 7 2 9 9 5 15 -4 7 1 6 10 -2 9 -7 14 -17 12 -22z m65 11
c9 -18 8 -20 -15 -20 -18 0 -25 5 -25 20 0 11 7 20 15 20 8 0 19 -9 25 -20z
m332 -5 c4 -21 3 -23 -13 -14 -22 11 -25 39 -5 39 7 0 16 -11 18 -25z m87 11
c9 -10 8 -15 -3 -19 -16 -7 -26 1 -26 20 0 17 14 17 29 -1z m218 -8 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1617 -14 c0 -8 -7 -14 -15 -14
-15 0 -21 21 -9 33 10 9 24 -2 24 -19z m380 -5 c0 -20 -24 -26 -35 -9 -8 13
14 42 26 34 5 -3 9 -14 9 -25z m260 16 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20
15 0 8 9 15 20 15 11 0 20 -7 20 -15z m-337 -8 c3 -7 -1 -21 -8 -31 -12 -16
-14 -16 -20 3 -10 33 16 59 28 28z m175 -4 c5 -17 -26 -29 -40 -15 -6 6 -7 15
-3 22 9 14 37 9 43 -7z m257 -3 c0 -8 -10 -16 -22 -18 -18 -3 -23 2 -23 18 0
16 5 21 23 18 12 -2 22 -10 22 -18z m80 10 c3 -5 -1 -10 -9 -10 -9 0 -16 5
-16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m346 -34 c-14 -16 -41 -11 -41 8 0 7
5 17 12 24 19 19 46 -11 29 -32z m342 19 c1 -5 -6 -11 -15 -13 -11 -2 -18 3
-18 13 0 17 30 18 33 0z m-1698 -5 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6
4 10 9 10 6 0 13 -4 16 -10z m260 -10 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13
17 0 13 5 20 13 18 6 -3 12 -11 12 -18z m153 -13 c2 -12 -3 -17 -17 -17 -12 0
-21 6 -21 13 0 31 32 34 38 4z m842 4 c0 -11 -4 -22 -8 -25 -14 -9 -35 12 -29
28 9 23 37 20 37 -3z m178 -4 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25
33 22 38 -4z m342 -1 c0 -20 -19 -31 -32 -18 -6 6 -7 15 -4 21 10 16 36 13 36
-3z m150 5 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4
10 -9z m70 -23 c0 -17 -23 -3 -26 16 -2 14 0 15 12 5 8 -6 14 -16 14 -21z
m-496 6 c9 -3 16 -14 16 -23 0 -9 11 -28 24 -42 27 -29 19 -59 -14 -59 -24 0
-27 35 -4 44 19 7 9 24 -22 36 -14 6 -24 18 -24 30 0 22 1 23 24 14z m-1166
-28 c3 -13 -1 -17 -14 -14 -11 2 -20 11 -22 22 -3 13 1 17 14 14 11 -2 20 -11
22 -22z m290 0 c6 -27 -14 -42 -32 -27 -12 10 -13 18 -5 33 12 22 32 19 37 -6z
m421 10 c9 -10 9 -16 1 -21 -16 -10 -40 2 -40 20 0 19 24 20 39 1z m-233 -35
c-4 -6 -16 -11 -27 -11 -21 0 -27 35 -6 43 16 6 41 -18 33 -32z m154 15 c0 -6
-4 -15 -8 -21 -8 -12 -44 -16 -37 -5 3 5 0 12 -5 15 -7 5 -5 12 6 20 20 14 44
9 44 -9z m160 4 c0 -13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33 -13z
m740 -10 c0 -13 -5 -17 -20 -13 -22 6 -27 29 -7 36 18 6 27 -1 27 -23z m-1910
5 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z
m763 1 c6 -16 -11 -36 -30 -36 -7 0 -13 11 -13 25 0 18 5 25 19 25 10 0 21 -6
24 -14z m164 -1 c7 -19 4 -22 -22 -27 -14 -2 -21 3 -23 20 -3 17 2 22 18 22
11 0 23 -7 27 -15z m711 -2 c6 -19 -28 -29 -39 -12 -5 8 -9 18 -9 22 0 13 43
3 48 -10z m702 7 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0
9 -4 9 -10z m-2515 -10 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16
10 8 0 12 -4 9 -10z m278 3 c4 -3 2 -12 -4 -20 -9 -10 -15 -10 -25 -2 -8 6
-11 15 -8 20 6 11 28 12 37 2z m162 -3 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
0 6 2 10 4 10 3 0 8 -4 11 -10z m231 -37 c-5 -12 -9 -13 -21 -3 -11 9 -12 17
-4 33 11 19 12 19 21 3 5 -9 7 -24 4 -33z m774 32 c15 -18 5 -35 -20 -35 -22
0 -34 15 -26 35 7 19 30 19 46 0z m458 -13 c3 -16 -2 -22 -16 -22 -24 0 -35
23 -19 39 16 16 31 8 35 -17z m-1138 -8 c0 -19 -28 -30 -42 -16 -6 6 -8 17 -5
26 7 18 47 10 47 -10z m750 6 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13
7 20 20 20 13 0 20 -7 20 -20z m-929 -17 c-9 -33 -35 -37 -39 -6 -2 16 3 23
20 26 13 2 24 5 25 6 1 0 -2 -11 -6 -26z m1003 2 c8 -23 -18 -37 -39 -21 -16
12 -17 16 -6 29 14 17 37 13 45 -8z m578 3 c-5 -21 -42 -25 -42 -5 0 19 9 26
30 24 11 -1 16 -7 12 -19z m233 -9 c-11 -17 -35 -9 -35 11 0 18 3 19 20 10 11
-6 18 -15 15 -21z m-2045 11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m1328 -23 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40
10 -2 19 -11 21 -21z m297 6 c16 -15 16 -17 0 -25 -23 -13 -45 -2 -45 22 0 24
22 26 45 3z m-1920 -23 c-5 -8 -11 -8 -17 -2 -6 6 -7 16 -3 22 5 8 11 8 17 2
6 -6 7 -16 3 -22z m441 19 c7 -12 -15 -31 -28 -23 -4 3 -8 12 -8 20 0 17 26
19 36 3z m422 -47 c-2 -9 -13 -18 -26 -20 -30 -5 -40 24 -16 47 16 14 21 15
32 4 7 -7 12 -21 10 -31z m90 24 c6 -31 -43 -50 -54 -21 -8 20 15 47 35 43 9
-2 17 -12 19 -22z m59 13 c17 -17 4 -39 -23 -39 -17 0 -24 6 -24 18 0 32 25
43 47 21z m88 -14 c0 -13 -8 -21 -22 -23 -19 -3 -23 1 -23 23 0 22 4 26 23 23
14 -2 22 -10 22 -23z m70 1 c0 -22 -14 -29 -34 -17 -19 12 -6 43 16 39 10 -2
18 -12 18 -22z m-405 -1 c18 -21 5 -47 -22 -43 -26 3 -37 36 -16 49 18 12 24
11 38 -6z m485 -5 c8 -24 -1 -40 -21 -40 -17 0 -28 24 -20 45 8 22 34 18 41
-5z m84 1 c13 -23 -10 -47 -34 -37 -18 7 -20 42 -2 49 21 9 26 7 36 -12z m681
0 c0 -10 -7 -24 -15 -31 -12 -10 -15 -10 -15 0 0 7 -6 10 -14 7 -11 -4 -11 -1
-2 19 13 29 46 32 46 5z m168 -2 c2 -9 -5 -19 -15 -21 -14 -4 -19 1 -21 19 -1
18 2 24 15 21 10 -2 19 -10 21 -19z m-2028 -3 c0 -8 -5 -18 -11 -22 -14 -8
-33 11 -25 25 10 16 36 13 36 -3z m230 -6 c0 -23 -35 -28 -43 -6 -7 18 1 26
25 26 11 0 18 -7 18 -20z m1398 -8 c3 -18 -1 -22 -22 -22 -19 0 -26 5 -26 18
0 39 43 43 48 4z m-1100 9 c19 -11 11 -45 -11 -49 -19 -4 -32 20 -23 43 6 17
15 19 34 6z m739 -7 c8 -23 -12 -37 -41 -30 -23 6 -25 9 -16 26 13 24 48 26
57 4z m73 -24 c0 -24 -32 -27 -50 -5 -10 12 -10 18 0 30 16 19 50 2 50 -25z
m356 25 c4 -8 0 -22 -7 -31 -11 -13 -14 -13 -27 0 -17 17 -9 46 13 46 9 0 18
-7 21 -15z m-1791 -15 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18
18 9 -2 17 -10 17 -18z m1518 -26 c-3 -8 -16 -14 -29 -14 -19 0 -24 5 -24 24
0 15 7 26 18 29 21 4 43 -19 35 -39z m910 31 c1 -5 -6 -11 -15 -13 -11 -2 -18
3 -18 13 0 17 30 18 33 0z m-1878 -20 c0 -13 -8 -21 -22 -23 -17 -3 -23 2 -23
16 0 10 3 22 7 26 13 13 38 1 38 -19z m1490 -3 c1 -13 -3 -26 -8 -29 -6 -4 -7
-1 -2 7 6 10 4 12 -8 7 -18 -7 -42 17 -32 33 12 20 48 7 50 -18z m-1815 -2 c0
-13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z
m1390 -10 c0 -9 -9 -25 -19 -34 -18 -17 -20 -17 -30 2 -7 14 -6 24 5 40 16 24
44 19 44 -8z m516 -9 c-9 -14 -33 -14 -41 -1 -4 6 -1 18 6 26 11 13 14 13 27
0 8 -8 11 -19 8 -25z m-2206 15 c0 -8 -5 -18 -11 -22 -14 -8 -33 11 -25 25 10
16 36 13 36 -3z m240 -1 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15
15 8 0 15 -7 15 -15z m305 -10 c0 -13 -8 -21 -22 -23 -17 -3 -23 2 -23 16 0
10 3 22 7 26 13 13 38 1 38 -19z m1394 -21 c-8 -10 -16 -11 -30 -3 -22 11 -24
20 -7 37 17 17 53 -15 37 -34z m86 5 c0 -17 -6 -25 -21 -27 -23 -3 -29 23 -11
46 15 19 32 9 32 -19z m335 -3 c-13 -13 -35 7 -25 24 5 8 11 8 21 -1 10 -8 12
-15 4 -23z m-1890 8 c11 -12 10 -18 -3 -31 -8 -9 -23 -13 -31 -10 -19 8 -21
43 -3 50 20 9 24 8 37 -9z m1297 0 c2 -6 -5 -20 -14 -31 -15 -17 -20 -18 -31
-7 -13 15 -16 37 -5 48 10 10 46 3 50 -10z m-1599 -16 c-5 -34 -38 -37 -38 -4
0 20 5 26 21 26 15 0 20 -5 17 -22z m2092 -19 c0 -4 -7 -10 -15 -13 -19 -7
-30 17 -20 42 6 16 7 16 21 -2 8 -11 14 -23 14 -27z m405 31 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-2880 -11 c10 -15 -1
-23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m609 5 c23 -9 20 -44 -3
-44 -23 0 -35 15 -27 35 6 17 8 17 30 9z m1431 -25 c0 -17 -6 -25 -22 -27 -19
-3 -23 1 -23 21 0 25 14 38 35 33 6 -2 10 -14 10 -27z m272 6 c3 -9 3 -18 0
-21 -8 -8 -57 -12 -53 -5 1 3 6 14 9 24 8 21 36 22 44 2z m353 -15 c0 -11 -6
-20 -14 -20 -18 0 -29 16 -21 30 11 18 35 11 35 -10z m115 2 c0 -18 -20 -15
-23 4 -3 10 1 15 10 12 7 -3 13 -10 13 -16z m-2635 -1 c0 -5 -7 -11 -15 -15
-9 -3 -15 0 -15 9 0 8 7 15 15 15 8 0 15 -4 15 -9z m135 -11 c0 -8 -8 -16 -17
-18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m263 -2 c-4 -27
-33 -36 -42 -13 -8 22 1 35 25 35 15 0 20 -6 17 -22z m2322 -10 c0 -26 -23
-18 -25 9 -1 14 3 19 12 16 7 -3 13 -14 13 -25z m-732 -6 c3 -20 0 -23 -20
-20 -15 2 -24 11 -26 26 -3 20 0 23 20 20 15 -2 24 -11 26 -26z m616 12 c3 -8
2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m-2844 -9 c0 -8 -7 -15 -15
-15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m574 6 c10 -16 -5
-41 -24 -41 -19 0 -34 25 -24 41 3 5 14 9 24 9 10 0 21 -4 24 -9z m1741 -15
c-4 -36 -12 -45 -36 -39 -28 8 -21 45 11 54 14 4 26 7 26 8 1 1 0 -10 -1 -23z
m309 3 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z
m-2294 -4 c0 -19 -36 -39 -45 -25 -11 18 3 40 25 40 11 0 20 -7 20 -15z m-177
-1 c3 -3 3 -12 0 -20 -4 -11 -9 -12 -19 -3 -8 6 -14 15 -14 20 0 10 24 12 33
3z m325 -3 c19 -12 11 -45 -12 -49 -24 -5 -37 17 -25 40 11 20 18 22 37 9z
m2020 -14 c2 -11 -5 -20 -20 -26 -18 -7 -25 -4 -35 13 -15 27 -7 38 25 34 16
-2 28 -10 30 -21z m75 -17 c5 -20 2 -22 -44 -24 l-26 -2 21 32 c22 34 39 32
49 -6z m-205 6 c7 -32 -6 -48 -28 -36 -23 12 -26 30 -8 48 16 16 32 10 36 -12z
m522 9 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9 15 20 15 11 0 20 -7 20
-15z m150 0 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z
m-3092 -7 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m112 -2
c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25 9 15 25 12 25 -4z m-290 -6 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m630 -6
c11 -12 11 -18 -1 -30 -13 -12 -18 -12 -34 0 -16 12 -17 16 -6 30 17 20 25 20
41 0z m-137 -1 c8 -7 -23 -32 -40 -33 -7 0 -13 9 -13 20 0 14 7 20 23 20 13 0
27 -3 30 -7z m2180 -17 c16 -13 16 -15 2 -30 -21 -20 -44 -21 -51 -1 -6 15 11
45 25 45 3 0 14 -7 24 -14z m282 4 c3 -5 4 -14 0 -20 -8 -13 -35 -1 -35 16 0
16 26 19 35 4z m63 -21 c-16 -10 -33 5 -23 21 5 8 11 8 21 -1 12 -10 12 -14 2
-20z m-140 -7 c3 -17 -2 -22 -17 -22 -14 0 -21 6 -21 18 0 38 33 41 38 4z
m-2760 0 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2 31 -12z m250 1
c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18
-17z m190 -11 c3 -18 -1 -22 -22 -22 -19 0 -26 5 -26 18 0 39 43 43 48 4z
m-308 -2 c0 -13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33 -13z m2947
-14 c-4 -9 -13 -13 -21 -10 -17 6 -17 25 -1 36 14 10 29 -8 22 -26z m-463 7
c4 -9 1 -23 -7 -30 -7 -8 -21 -11 -30 -7 -17 6 -23 35 -10 47 12 13 41 7 47
-10z m211 -29 c0 -10 -8 -20 -18 -22 -22 -4 -35 27 -16 39 20 12 34 5 34 -17z
m382 12 c0 -8 -6 -12 -14 -9 -7 3 -13 9 -13 14 0 5 6 9 13 9 8 0 14 -6 14 -14z
m-2993 -5 c3 -4 0 -15 -7 -24 -15 -21 -43 -6 -34 17 7 17 32 22 41 7z m156
-22 c0 -12 -7 -19 -20 -19 -19 0 -28 28 -14 43 11 11 34 -5 34 -24z m2611 -2
c-5 -5 -17 -7 -26 -3 -14 5 -15 9 -5 21 10 12 16 13 27 4 9 -8 10 -16 4 -22z
m-2934 7 c8 -20 -8 -36 -26 -25 -15 10 -8 41 9 41 6 0 13 -7 17 -16z m2651
-18 c5 -25 -12 -39 -37 -31 -20 6 -28 34 -14 49 13 13 48 1 51 -18z m189 -2
c6 -18 -15 -36 -34 -30 -19 6 -28 36 -11 36 5 0 6 4 3 10 -4 6 2 7 16 1 13 -4
24 -12 26 -17z m-2749 -7 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19
-11 21 -21z m3006 2 c-3 -5 -12 -9 -20 -9 -9 0 -12 -5 -8 -12 4 -7 3 -8 -4 -4
-17 11 4 47 23 40 8 -4 12 -10 9 -15z m-3404 1 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m558 -12 c-4 -27 -33 -36 -42 -13
-8 22 1 35 25 35 15 0 20 -6 17 -22z m-318 -12 c0 -15 -24 -28 -34 -19 -13 14
-5 33 14 33 11 0 20 -6 20 -14z m2788 -10 c2 -10 0 -22 -5 -27 -16 -16 -43 2
-43 28 0 20 4 24 22 21 13 -2 24 -11 26 -22z m298 2 c10 -13 15 -20 10 -16 -4
4 -18 3 -31 -2 -19 -7 -22 -5 -20 13 3 27 3 27 13 27 5 0 17 -10 28 -22z m34
-3 c14 -19 14 -19 -2 -6 -10 7 -18 18 -18 24 0 6 1 8 3 6 1 -2 9 -13 17 -24z
m95 -5 c0 -23 -32 -23 -45 0 -11 20 -9 21 17 18 17 -2 28 -9 28 -18z m-2958
-6 c3 -8 1 -20 -6 -27 -14 -14 -47 15 -37 32 10 16 36 13 43 -5z m-170 -10 c3
-8 0 -21 -6 -27 -10 -9 -16 -8 -27 7 -11 16 -11 22 -1 28 18 12 27 10 34 -8z
m2753 1 c17 -20 5 -45 -20 -45 -11 0 -23 7 -26 15 -6 15 11 45 26 45 4 0 13
-7 20 -15z m138 -13 c3 -16 -2 -22 -17 -22 -24 0 -35 15 -27 36 9 23 40 13 44
-14z m397 19 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z
m-3467 -13 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m256 -4
c20 -8 21 -54 1 -54 -17 0 -34 29 -28 48 5 14 6 14 27 6z m-489 -14 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m3465 -10 c0
-15 -7 -20 -25 -20 -24 0 -34 18 -18 33 15 15 43 7 43 -13z m-3130 -11 c0 -24
-23 -21 -28 4 -2 10 3 17 12 17 10 0 16 -9 16 -21z m2905 11 c-3 -5 -3 -10 2
-10 4 0 8 -11 8 -24 0 -13 -4 -24 -10 -23 -5 1 -9 -5 -7 -13 3 -11 -2 -10 -17
4 -34 31 -25 54 27 75 2 0 0 -3 -3 -9z m363 -24 c3 -13 -1 -17 -14 -14 -11 2
-20 11 -22 22 -3 13 1 17 14 14 11 -2 20 -11 22 -22z m-238 -5 c0 -27 -15 -34
-34 -15 -19 19 -12 34 15 34 12 0 19 -7 19 -19z m175 -1 c0 -7 -6 -15 -12 -17
-8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z m-3365 -4 c0 -8 -5
-18 -11 -22 -14 -8 -33 11 -25 25 10 16 36 13 36 -3z m260 -17 c0 -12 -7 -19
-19 -19 -26 0 -36 17 -21 35 15 18 40 8 40 -16z m-145 1 c4 -6 1 -18 -6 -26
-11 -13 -14 -13 -27 0 -8 8 -11 19 -8 25 9 14 33 14 41 1z m3023 -37 c-1 -19
-6 -37 -12 -40 -5 -3 -6 -1 -3 4 4 6 -2 13 -13 16 -15 4 -20 13 -18 34 2 25 6
29 25 25 20 -4 23 -10 21 -39z m381 34 c13 -16 5 -27 -21 -27 -18 0 -25 20
-11 33 10 10 19 8 32 -6z m-259 -28 c6 -22 4 -25 -14 -23 -11 1 -24 3 -28 3
-10 1 -10 35 -1 45 14 13 37 1 43 -25z m-3410 6 c0 -8 -4 -15 -10 -15 -5 0
-10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m350 -26 c0 -15 -30 -23 -42
-11 -6 6 -8 18 -4 27 4 13 10 14 26 5 11 -6 20 -15 20 -21z m3207 13 c10 -6
10 -12 -1 -28 -14 -18 -16 -18 -32 -2 -26 26 1 51 33 30z m-3473 -23 c-3 -5
-10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m95 -2 c8
-10 7 -18 -2 -31 -11 -16 -15 -16 -25 -3 -12 14 -7 47 8 47 4 0 13 -6 19 -13z
m3175 -14 c4 -9 0 -23 -9 -32 -13 -14 -17 -14 -31 1 -9 9 -14 24 -11 32 8 21
43 20 51 -1z m122 -29 c-13 -9 -15 -14 -6 -14 7 0 10 -3 7 -7 -4 -3 -16 2 -27
12 -19 17 -19 19 -3 37 15 17 18 17 32 3 15 -15 14 -18 -3 -31z m104 32 c0 -2
-8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-3293 -32 c9 -24
-18 -36 -39 -17 -10 9 -15 20 -12 24 9 15 44 10 51 -7z m3116 -3 c8 -30 -11
-53 -34 -41 -24 13 -25 36 -1 49 24 15 29 13 35 -8z m227 -29 c-12 -2 -28 -1
-37 2 -14 6 -13 9 3 27 l19 21 18 -24 c19 -23 19 -23 -3 -26z m-93 4 c-9 -23
-37 -20 -37 3 0 21 20 33 34 20 5 -4 6 -15 3 -23z m-3737 8 c0 -8 -4 -12 -10
-9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m4160 -3 c0 -6 -4 -13
-10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-4060 -15 c0 -8
-4 -17 -9 -21 -12 -7 -24 12 -16 25 9 15 25 12 25 -4z m64 -7 c-3 -5 -10 -7
-15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m161 1 c0 -22 -29
-18 -33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z m99 11 c9 -14 -4 -41 -19
-41 -15 0 -28 27 -19 41 3 5 11 9 19 9 8 0 16 -4 19 -9z m3646 -11 c0 -13 -5
-18 -15 -14 -11 5 -12 9 -2 20 16 18 17 17 17 -6z m-90 -15 c0 -19 -13 -25
-30 -15 -11 7 -11 11 -1 24 15 17 31 13 31 -9z m-92 -14 c3 -17 -1 -22 -15
-20 -10 2 -24 0 -30 -4 -9 -6 -11 -3 -6 10 16 47 45 55 51 14z m-104 13 c18
-7 21 -39 5 -50 -16 -9 -43 21 -36 40 7 18 9 18 31 10z m-106 -31 c-3 -25 0
-28 25 -25 32 4 47 -22 25 -44 -19 -19 -46 -8 -50 19 -2 12 -12 23 -23 25 -46
9 -45 52 0 52 23 0 26 -4 23 -27z m-3400 0 c5 -17 -26 -29 -40 -15 -6 6 -7 15
-3 22 9 14 37 9 43 -7z m97 7 c10 -16 -5 -50 -21 -50 -20 0 -29 27 -14 45 14
17 26 19 35 5z m3672 -36 c-3 -4 -14 -3 -24 0 -15 6 -15 8 -3 16 16 10 39 -4
27 -16z m-77 -4 c9 -17 8 -20 -13 -20 -29 0 -37 7 -30 26 8 20 31 17 43 -6z
m-3860 -9 c0 -5 -7 -14 -15 -21 -8 -7 -15 -10 -15 -7 0 3 -3 12 -6 21 -4 12 0
16 15 16 12 0 21 -4 21 -9z m96 -20 c-10 -16 -36 -13 -36 3 0 17 21 27 34 16
4 -5 5 -13 2 -19z m3654 3 c7 -16 4 -20 -11 -19 -25 2 -43 20 -34 35 10 17 37
8 45 -16z m-3540 -13 c0 -23 -23 -34 -39 -18 -19 19 -12 37 14 37 18 0 25 -5
25 -19z m3460 -11 c33 -18 1 -57 -35 -44 -15 6 -20 35 -8 47 9 10 21 9 43 -3z
m225 -2 c3 -3 7 -14 9 -24 3 -14 -2 -19 -19 -19 -13 0 -21 3 -18 8 2 4 -2 7
-9 7 -10 0 -10 2 1 9 7 5 11 13 7 19 -6 10 19 11 29 0z m102 -3 c4 -4 3 -5 -4
-1 -6 3 -14 2 -18 -4 -10 -16 -35 -12 -35 5 0 17 42 17 57 0z m86 -10 c8 -18
-11 -31 -25 -17 -11 11 -3 32 12 32 4 0 10 -7 13 -15z m-4133 -4 c0 -5 -6 -13
-13 -19 -15 -13 -42 1 -33 17 8 13 46 15 46 2z m98 -13 c9 -9 7 -14 -6 -24
-20 -15 -44 0 -35 22 6 17 25 18 41 2z m3758 -27 c-4 -6 -16 -11 -27 -11 -21
0 -27 35 -6 43 16 6 41 -18 33 -32z m-3646 14 c0 -9 -7 -18 -16 -22 -18 -7
-39 11 -30 26 11 17 46 13 46 -4z m3575 -40 c0 -22 -31 -33 -47 -17 -17 17 -1
44 24 40 15 -2 23 -10 23 -23z m-3939 4 c3 -6 1 -16 -5 -22 -9 -9 -14 -8 -21
3 -9 15 -4 30 10 30 5 0 12 -5 16 -11z m84 2 c0 -14 -18 -23 -30 -16 -6 4 -8
11 -5 16 8 12 35 12 35 0z m107 -7 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43
6 0 13 -7 17 -16z m3867 4 c-3 -4 1 -8 8 -8 10 0 10 -2 -1 -9 -7 -5 -11 -13
-8 -18 3 -5 -6 -9 -21 -8 -28 0 -44 31 -23 44 15 9 51 8 45 -1z m71 -19 c-10
-15 -22 -10 -28 11 -4 17 -2 18 14 9 11 -5 17 -14 14 -20z m103 8 c2 -10 -3
-17 -12 -17 -18 0 -29 16 -21 31 9 14 29 6 33 -14z m-3920 -21 c-2 -13 -12
-22 -25 -24 -19 -3 -23 1 -23 21 0 14 6 27 13 30 22 7 38 -5 35 -27z m4065 -9
c13 -21 13 -23 -4 -23 -13 0 -18 7 -17 23 2 13 3 23 4 23 1 0 8 -10 17 -23z
m-393 -2 c0 -8 6 -15 13 -15 6 -1 0 -8 -16 -16 -15 -8 -32 -12 -37 -9 -11 7
-13 38 -3 48 12 13 43 7 43 -8z m313 -29 c-5 -10 -13 -12 -23 -6 -11 7 -12 11
0 25 16 20 36 4 23 -19z m-4243 -1 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0
8 5 15 10 15 6 0 10 -7 10 -15z m4040 -11 c0 -33 -33 -30 -38 4 -3 17 2 22 17
22 16 0 21 -6 21 -26z m-3841 -30 c-14 -14 -17 -14 -29 0 -10 13 -10 19 0 32
12 14 15 14 29 0 14 -15 14 -17 0 -32z m-101 19 c-2 -10 -10 -18 -18 -18 -8 0
-16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m3878 -24 c9 -16 -20 -41
-39 -33 -17 6 -22 25 -10 37 10 11 41 8 49 -4z m303 -8 c11 -7 9 -10 -8 -16
-24 -7 -44 2 -36 16 8 11 26 11 44 0z m83 -26 c-12 -22 -27 -2 -18 22 5 12 10
13 17 6 7 -7 8 -17 1 -28z m112 26 c3 -5 0 -14 -8 -20 -10 -9 -15 -8 -19 3 -9
22 15 37 27 17z m-4620 -10 c3 -5 -1 -14 -8 -20 -16 -13 -32 2 -21 19 8 12 22
13 29 1z m4264 -14 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11
21 -21z m82 -2 c0 -19 -20 -29 -32 -17 -15 15 -2 43 17 36 8 -3 15 -12 15 -19z
m-4230 0 c0 -8 -9 -15 -20 -15 -20 0 -26 11 -13 23 12 13 33 7 33 -8z m70 0
c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m107 -1 c8 -21
-13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z m3878 -35 c5 -14 2 -25
-6 -30 -19 -12 -25 -11 -44 7 -12 12 -13 19 -4 30 17 21 47 17 54 -7z m98 -8
c4 -1 7 -12 7 -25 0 -27 -23 -41 -39 -22 -15 18 -10 54 9 51 8 -2 18 -4 23 -4z
m-4225 -19 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m205 5
c8 -20 -9 -41 -27 -34 -23 9 -21 47 3 47 10 0 21 -6 24 -13z m4133 -16 c-10
-16 -36 -13 -36 3 0 17 21 27 34 16 4 -5 5 -13 2 -19z m104 10 c0 -23 -16 -36
-25 -21 -9 14 1 40 15 40 5 0 10 -9 10 -19z m106 8 c10 -17 -13 -36 -27 -22
-12 12 -4 33 11 33 5 0 12 -5 16 -11z m-4446 -24 c13 -16 6 -35 -15 -35 -18 0
-28 17 -21 35 7 19 20 19 36 0z m2095 -27 c27 -11 67 -32 89 -48 l39 -30 32
20 c64 40 120 21 161 -54 25 -45 29 -63 32 -158 3 -87 7 -108 19 -108 8 0 28
-13 44 -29 54 -55 34 -130 -58 -210 -41 -37 -130 -88 -144 -84 -4 1 -4 -17 -2
-41 5 -37 1 -48 -24 -76 -39 -44 -96 -50 -178 -20 -33 12 -68 22 -77 23 -9 1
-14 5 -12 9 3 4 2 8 -3 8 -4 0 -21 12 -38 26 -29 24 -29 25 -5 18 24 -6 24 -5
6 9 -18 13 -22 12 -50 -14 -25 -23 -40 -29 -78 -29 -42 0 -50 4 -73 35 -38 49
-55 115 -55 211 0 83 0 84 -30 99 -47 24 -64 58 -56 111 8 60 73 130 164 176
l67 33 -6 42 c-3 23 -2 40 3 37 4 -3 8 1 8 9 0 8 9 22 19 31 41 38 117 39 206
4z m1977 14 c17 -17 1 -44 -24 -40 -14 2 -24 13 -29 29 -6 24 -4 27 18 24 13
-1 29 -7 35 -13z m-4247 -22 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5
20 13 18 6 -3 12 -11 12 -18z m4345 16 c0 -2 -9 -6 -20 -9 -11 -3 -18 -1 -14
4 5 9 34 13 34 5z m130 -21 c0 -19 -27 -38 -35 -25 -3 6 -11 8 -16 5 -5 -4 -4
5 2 18 8 17 17 23 30 20 10 -3 19 -11 19 -18z m-4350 -14 c0 -21 -26 -36 -36
-20 -9 15 3 39 21 39 8 0 15 -9 15 -19z m110 -1 c0 -24 -26 -38 -42 -22 -16
16 -2 42 22 42 13 0 20 -7 20 -20z m4140 -4 c0 -18 -5 -21 -45 -30 -18 -4 -25
-3 -17 2 7 5 12 13 11 18 -3 19 2 24 26 24 15 0 25 -6 25 -14z m-86 -50 c-3
-8 -15 -16 -28 -18 -17 -4 -22 0 -24 20 -1 14 3 31 10 39 10 13 15 12 30 -6 9
-12 15 -28 12 -35z m288 9 c-3 -17 -10 -25 -20 -23 -25 5 -25 24 0 36 13 6 24
11 24 11 1 1 -1 -10 -4 -24z m-4680 -27 c-15 -15 -26 -4 -18 18 5 13 9 15 18
6 9 -9 9 -15 0 -24z m194 17 c4 -8 0 -22 -7 -31 -11 -13 -14 -13 -27 0 -17 17
-9 46 13 46 9 0 18 -7 21 -15z m102 -13 c3 -20 0 -23 -20 -20 -15 2 -24 11
-26 26 -3 20 0 23 20 20 15 -2 24 -11 26 -26z m4235 19 c-5 -3 -12 -14 -16
-23 -7 -20 -32 -24 -42 -8 -10 17 13 38 41 38 14 0 22 -3 17 -7z m-4415 -24
c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m4315 -35 c-8 -22
-23 -31 -40 -25 -16 6 -17 35 -1 51 17 17 49 -3 41 -26z m170 19 c4 -15 -2
-20 -24 -23 -15 -3 -26 -1 -23 3 2 4 -1 11 -7 15 -8 4 -7 9 2 15 23 15 46 10
52 -10z m190 -17 c-7 -18 -33 -18 -33 -1 0 19 10 27 25 20 8 -2 11 -11 8 -19z
m-4539 -10 c23 -9 20 -44 -3 -44 -23 0 -35 15 -27 35 6 17 8 17 30 9z m-176
-36 c-2 -13 -7 -23 -13 -23 -5 0 -11 10 -13 23 -2 15 2 22 13 22 11 0 15 -7
13 -22z m4480 -6 c3 -16 -2 -22 -16 -22 -22 0 -37 36 -18 44 19 8 31 0 34 -22z
m102 8 c6 -11 9 -20 8 -21 -36 -9 -67 8 -53 31 10 16 33 12 45 -10z m-4670 -4
c0 -20 -19 -31 -32 -18 -6 6 -7 15 -4 21 10 16 36 13 36 -3z m168 -9 c4 -20
-25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11 21 -21z m-290 2 c-2 -6 -8
-10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m4974 -4 c0
-5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z
m-372 -19 c0 -14 -4 -27 -9 -30 -15 -10 -41 5 -41 23 0 23 9 31 32 31 12 0 18
-7 18 -24z m266 9 c3 -8 3 -19 -1 -25 -10 -16 -35 7 -28 26 7 18 22 18 29 -1z
m-4508 -29 c-4 -26 -27 -28 -42 -3 -8 13 -8 20 3 26 21 14 42 1 39 -23z m4377
-2 c-9 -14 -14 -32 -11 -39 7 -19 -34 -31 -45 -14 -13 20 -6 34 19 37 12 2 27
12 32 23 6 10 13 19 17 19 3 0 -2 -12 -12 -26z m-4577 -21 c-2 -10 -10 -18
-18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m4646 -30
c-20 -15 -34 -8 -34 16 0 28 12 33 32 14 18 -16 18 -18 2 -30z m-4820 22 c3
-8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m256 -11
c0 -17 -26 -19 -36 -3 -7 12 15 31 28 23 4 -3 8 -12 8 -20z m4390 -9 c0 -29
-25 -44 -43 -26 -24 24 -10 51 26 51 12 0 17 -8 17 -25z m260 6 c0 -11 -4 -23
-10 -26 -5 -3 -10 -2 -10 4 0 5 -10 13 -22 17 -22 7 -22 7 -3 15 33 13 45 10
45 -10z m-4562 -35 c5 -24 -10 -38 -31 -30 -20 7 -23 40 -4 47 19 8 32 2 35
-17z m4342 -41 c0 -31 -24 -38 -46 -13 -18 19 -18 20 0 33 24 18 46 8 46 -20z
m-4442 -3 c3 -17 -2 -22 -18 -22 -25 0 -38 29 -19 41 20 13 34 6 37 -19z
m4529 22 c17 -5 20 -9 10 -18 -21 -21 -45 -26 -51 -9 -11 28 2 37 41 27z
m-4630 -20 c8 -20 -8 -36 -26 -25 -15 10 -8 41 9 41 6 0 13 -7 17 -16z m158
-39 c0 -30 -39 -27 -43 3 -3 20 0 23 20 20 15 -2 23 -10 23 -23z m-105 -14 c0
-23 -9 -31 -26 -24 -17 6 -18 25 -2 41 14 14 28 6 28 -17z m4615 0 c0 -23 -4
-28 -19 -25 -24 4 -26 7 -31 33 -3 17 1 21 23 21 23 0 27 -4 27 -29z m-4705
-7 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z m4797 10 c3 -8
1 -20 -6 -27 -14 -14 -47 15 -37 32 10 16 36 13 43 -5z m161 5 c-2 -6 -8 -10
-13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-342 -30 c9
-15 -19 -41 -38 -34 -9 4 -18 14 -21 24 -3 13 3 17 24 19 15 1 31 -3 35 -9z
m-4806 -15 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28 12 0 21 -6 21 -16z m110
-4 c0 -11 -6 -20 -14 -20 -18 0 -29 16 -21 30 11 18 35 11 35 -10z m248 -18
c3 -16 -2 -22 -17 -22 -24 0 -35 15 -27 36 9 23 40 13 44 -14z m4722 -11 c0
-14 -5 -18 -20 -14 -16 4 -18 8 -9 19 16 20 29 17 29 -5z m-4910 -16 c0 -26
-14 -33 -25 -15 -9 14 1 40 15 40 5 0 10 -11 10 -25z m88 2 c2 -12 -3 -17 -17
-17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z m4652 8 c-7 -8 -9 -15 -4 -15 5 0 1
-5 -9 -11 -14 -8 -22 -8 -30 0 -13 13 -2 27 28 34 11 3 22 5 24 6 2 0 -2 -6
-9 -14z m69 -32 c-17 -20 -47 -12 -41 12 7 29 24 36 38 17 8 -11 9 -21 3 -29z
m-4896 -10 c-7 -17 -29 -17 -37 2 -6 18 26 39 35 23 4 -6 5 -17 2 -25z m4757
23 c0 -8 -7 -22 -16 -31 -15 -15 -18 -15 -35 1 -14 13 -16 20 -8 31 15 18 59
17 59 -1z m261 -31 c-1 -15 -24 -12 -29 3 -3 9 2 13 12 10 10 -1 17 -7 17 -13z
m-4771 -14 c0 -27 -19 -36 -37 -18 -18 18 -9 37 18 37 12 0 19 -7 19 -19z
m-370 -6 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m200 -20
c0 -21 -19 -28 -36 -14 -14 12 -14 14 2 26 19 14 34 9 34 -12z m4759 -31 c-9
-11 -15 -12 -25 -3 -22 18 -17 47 9 51 26 4 36 -24 16 -48z m-4691 18 c3 -18
-2 -22 -22 -22 -28 0 -34 18 -14 38 17 17 32 10 36 -16z m4618 16 c17 -27 11
-44 -15 -43 -21 0 -27 6 -29 28 -3 21 1 27 17 27 11 0 23 -6 27 -12z m-4796
-26 c0 -26 -11 -34 -27 -21 -10 9 -10 15 -2 25 16 19 29 17 29 -4z m4973 12
c11 -11 -5 -34 -24 -34 -12 0 -19 7 -19 20 0 19 28 28 43 14z m-4715 -32 c3
-18 -1 -22 -21 -22 -28 0 -41 22 -24 39 17 17 42 9 45 -17z m4802 5 c0 -19
-10 -27 -25 -20 -17 6 -8 33 11 33 8 0 14 -6 14 -13z m96 -14 c-3 -17 -26 -32
-26 -16 0 8 20 33 26 33 1 0 1 -8 0 -17z m-336 -18 c0 -16 -17 -29 -41 -30
-19 -2 -19 33 1 45 17 11 40 2 40 -15z m80 -17 c0 -28 -42 -20 -46 8 -3 18 1
20 21 16 17 -3 25 -11 25 -24z m-5060 2 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m330 -10 c0 -15 -7 -20 -25 -20 -24 0
-34 18 -18 33 15 15 43 7 43 -13z m4827 4 c7 -18 -17 -38 -31 -24 -11 11 -1
40 14 40 6 0 13 -7 17 -16z m-4937 -20 c0 -8 -9 -14 -20 -14 -19 0 -27 19 -14
33 10 9 34 -4 34 -19z m-72 -1 c-4 -22 -22 -20 -26 1 -2 10 3 16 13 16 10 0
15 -7 13 -17z m260 -16 c2 -9 -4 -20 -12 -23 -21 -8 -46 13 -39 32 8 20 47 13
51 -9z m4668 -14 c-1 -21 -7 -29 -23 -31 -18 -3 -23 2 -23 18 0 12 9 24 23 30
12 5 22 9 23 9 1 1 1 -11 0 -26z m159 9 c0 -18 -20 -15 -23 4 -3 10 1 15 10
12 7 -3 13 -10 13 -16z m-5173 -7 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0
9 5 15 11 13 6 -2 11 -8 11 -13z m4936 -13 c3 -20 0 -23 -20 -20 -15 2 -24 11
-26 26 -3 20 0 23 20 20 15 -2 24 -11 26 -26z m342 -3 c0 -11 -4 -18 -10 -14
-5 3 -7 12 -3 20 7 21 13 19 13 -6z m-5032 -21 c-3 -25 -36 -37 -48 -17 -13
20 0 39 27 39 20 0 24 -4 21 -22z m75 -12 c3 -8 0 -21 -6 -30 -11 -15 -14 -15
-32 -1 -20 15 -20 15 -1 30 25 19 32 19 39 1z m4795 5 c11 -6 10 -11 -3 -25
-18 -17 -30 -22 -21 -7 4 5 1 11 -6 13 -6 2 -8 10 -4 16 8 14 16 15 34 3z
m-4958 -19 c0 -24 -8 -32 -26 -25 -22 8 -17 43 6 43 13 0 20 -7 20 -18z m-90
-8 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28 12 0 21 -6 21 -16z m5136 5 c3 -6
1 -16 -5 -22 -9 -9 -14 -8 -21 3 -5 8 -10 18 -10 23 0 11 28 8 36 -4z m149 -9
c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4 21 -10z
m-5137 -28 c3 -16 -2 -22 -16 -22 -22 0 -37 36 -18 44 19 8 31 0 34 -22z
m4737 -7 c0 -15 -7 -20 -26 -21 -22 -1 -25 3 -21 23 6 31 47 29 47 -2z m105
10 c0 -21 -27 -30 -45 -15 -19 16 -10 30 21 30 15 0 24 -6 24 -15z m-5200 -11
c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m286 -5 c7 -12 -15
-31 -28 -23 -4 3 -8 12 -8 20 0 17 26 19 36 3z m162 -26 c3 -19 -1 -23 -22
-23 -28 0 -41 14 -32 36 9 24 50 13 54 -13z m4832 18 c0 -6 -5 -13 -11 -16 -6
-4 -8 -13 -5 -21 3 -7 0 -16 -6 -20 -6 -4 -8 -3 -4 4 3 6 -1 15 -10 20 -22 12
-11 42 16 42 11 0 20 -4 20 -9z m-5205 -30 c7 -12 -12 -24 -25 -16 -11 7 -4
25 10 25 5 0 11 -4 15 -9z m93 -14 c2 -10 -3 -17 -12 -17 -18 0 -29 16 -21 31
9 14 29 6 33 -14z m5198 12 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0
12 -5 16 -11z m-5018 -22 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19
-11 21 -21z m4834 -6 c3 -23 1 -27 -14 -23 -23 6 -31 19 -24 37 9 25 34 17 38
-14z m-66 8 c3 -6 0 -18 -8 -26 -13 -13 -17 -12 -32 2 -9 9 -14 21 -11 26 8
13 42 11 51 -2z m-4838 -37 c2 -14 -2 -22 -12 -22 -19 0 -29 17 -22 36 8 22
30 12 34 -14z m157 -7 c0 -22 -31 -33 -47 -17 -17 17 -1 44 24 40 15 -2 23
-10 23 -23z m4848 11 c5 -13 -10 -36 -24 -36 -14 0 -19 34 -7 42 18 11 25 10
31 -6z m-5113 -47 c0 -10 -7 -19 -15 -19 -15 0 -21 31 -9 43 11 10 24 -3 24
-24z m5047 -6 c-9 -9 -23 -13 -32 -10 -13 5 -14 11 -5 27 9 17 14 18 32 9 18
-10 19 -12 5 -26z m-93 10 c4 -9 1 -20 -6 -25 -21 -13 -43 -4 -46 20 -3 18 1
22 21 22 14 0 28 -7 31 -17z m-5024 -14 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9
23 8 8 24 -1 24 -14z m5270 11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m-5025 -25 c0 -13 -8 -21 -22 -23 -17 -3 -23 2
-23 16 0 10 3 22 7 26 13 13 38 1 38 -19z m-107 -13 c2 -14 -2 -22 -12 -22
-19 0 -29 17 -22 36 8 22 30 12 34 -14z m5212 13 c0 -8 -4 -15 -10 -15 -5 0
-10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-5026 -12 c4 -9 0 -23 -9 -32
-13 -14 -17 -14 -30 0 -19 19 -10 49 15 49 10 0 21 -8 24 -17z m4869 -7 c3 -8
3 -17 0 -20 -13 -12 -31 -5 -36 14 -4 15 0 20 13 20 10 0 20 -6 23 -14z m-89
-33 c-9 -39 -17 -45 -39 -30 -21 16 -12 37 16 37 10 0 19 5 19 10 0 6 2 10 5
10 3 0 2 -12 -1 -27z m-76 -7 c5 -26 -26 -50 -45 -35 -16 14 -17 31 -1 47 17
17 42 11 46 -12z m-4968 4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m5207 -16 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8
-13 4 -17z m-5279 -8 c3 -13 -1 -17 -14 -14 -11 2 -20 11 -22 22 -3 13 1 17
14 14 11 -2 20 -11 22 -22z m259 -2 c3 -8 1 -20 -6 -27 -14 -14 -47 15 -37 32
10 16 36 13 43 -5z m71 -41 c3 -19 -1 -23 -22 -23 -28 0 -41 14 -32 36 9 24
50 13 54 -13z m-188 -3 c0 -11 -6 -20 -13 -20 -19 0 -27 10 -20 26 8 21 33 17
33 -6z m5090 5 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15
-7 15 -15z m-182 -16 c11 -4 10 -10 -5 -27 -24 -29 -49 -15 -39 23 5 19 10 24
19 17 7 -5 18 -11 25 -13z m339 -15 c-2 -2 -11 -2 -20 2 -13 4 -14 9 -4 19 10
11 14 10 20 -2 5 -8 7 -17 4 -19z m-5317 2 c0 -15 -24 -28 -34 -19 -13 14 -5
33 14 33 11 0 20 -6 20 -14z m5064 8 c18 -7 21 -30 6 -39 -15 -9 -40 14 -33
31 6 16 6 16 27 8z m-4881 -28 c7 -17 -11 -36 -35 -36 -19 0 -22 8 -12 34 8
20 40 21 47 2z m-95 -29 c4 -22 -27 -35 -38 -16 -10 15 7 42 24 36 6 -2 12
-11 14 -20z m166 7 c9 -22 -1 -34 -30 -34 -17 0 -24 6 -24 18 0 10 3 22 7 25
12 13 41 7 47 -9z m-344 -9 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15
15 15 8 0 15 -7 15 -15z m5060 -10 c10 -12 10 -18 0 -29 -14 -18 -40 -10 -40
13 0 32 20 40 40 16z m155 -4 c0 -22 -19 -23 -32 -2 -10 16 -8 18 10 17 12 -1
22 -7 22 -15z m-5135 -22 c0 -10 -7 -19 -15 -19 -15 0 -21 31 -9 43 11 10 24
-3 24 -24z m5074 25 c12 -5 13 -10 5 -25 -17 -33 -58 -18 -45 16 6 16 16 18
40 9z m186 2 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m-5070 -31 c9 -11 10 -19 3 -26 -14 -14 -43 0 -43 22 0 23 23 25 40
4z m5112 -10 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11
-8 11 -13z m-5034 -28 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19
-11 21 -21z m5137 -7 c-6 -9 -9 -9 -16 1 -10 17 0 34 13 21 6 -6 7 -16 3 -22z
m-5500 1 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z m191 -2
c9 -15 -11 -33 -30 -26 -9 4 -13 13 -10 22 7 17 30 20 40 4z m102 -32 c3 -27
-4 -32 -32 -21 -19 7 -21 40 -3 47 21 9 32 1 35 -26z m4800 11 c-4 -30 -48
-39 -48 -10 0 24 8 32 32 32 14 0 19 -6 16 -22z m165 7 c7 -16 -8 -29 -23 -20
-15 9 -12 35 4 35 8 0 16 -7 19 -15z m-1225 -293 l2 -280 -55 -4 c-39 -2 -56
1 -60 10 -3 8 -5 134 -5 282 l0 269 28 3 c15 2 41 3 57 2 l30 -1 3 -281z
m1141 275 c8 -11 6 -18 -9 -32 -18 -16 -20 -16 -26 -1 -5 14 -9 14 -22 4 -12
-10 -11 -7 2 15 20 30 38 34 55 14z m161 -12 c0 -19 -20 -29 -32 -17 -8 8 -7
16 2 26 14 17 30 12 30 -9z m-5224 -21 c-5 -13 -10 -14 -18 -6 -6 6 -8 18 -4
28 5 13 10 14 18 6 6 -6 8 -18 4 -28z m943 -86 c18 -68 38 -143 45 -168 l12
-45 33 120 c18 66 39 140 45 165 l13 45 58 3 c56 3 57 2 52 -20 -4 -13 -37
-137 -74 -276 l-68 -253 -59 3 -59 3 -23 90 c-13 50 -30 115 -39 145 -57 204
-75 276 -75 292 0 15 8 18 54 18 l53 0 32 -122z m701 67 l0 -55 -60 0 -60 0 0
-220 0 -221 -57 3 -58 3 -3 218 -2 217 -60 0 -60 0 0 48 c0 27 3 52 7 55 3 4
84 7 180 7 l173 0 0 -55z m234 39 c3 -9 6 -99 6 -200 0 -171 1 -185 20 -204
11 -11 33 -20 50 -20 17 0 39 9 50 20 19 19 20 33 20 204 0 101 3 191 6 200 5
12 20 16 61 16 l54 0 -3 -229 c-3 -215 -4 -231 -24 -258 -37 -50 -79 -67 -164
-67 -85 0 -127 17 -164 67 -20 27 -21 43 -24 258 l-3 229 54 0 c41 0 56 -4 61
-16z m710 0 c42 -20 73 -74 78 -134 l3 -45 -39 -3 c-48 -4 -89 5 -81 18 11 18
-5 47 -31 58 -36 17 -58 15 -88 -9 -25 -20 -26 -23 -26 -134 0 -139 7 -162 54
-170 46 -9 71 0 84 31 22 46 16 54 -35 54 l-48 0 3 55 4 56 101 -3 102 -3 -2
-104 c-3 -120 -21 -163 -82 -189 -53 -23 -169 -20 -217 5 -65 34 -79 74 -79
238 0 77 2 156 5 175 5 43 32 80 73 102 43 23 173 24 221 2z m527 12 c2 -2 2
-27 1 -55 l-4 -51 -89 0 -89 0 0 -55 0 -55 70 0 70 0 0 -55 0 -55 -70 0 -70 0
0 -55 0 -55 90 0 90 0 0 -55 0 -55 -150 0 -150 0 0 275 0 275 148 0 c82 0 151
-2 153 -4z m491 -32 c24 -16 56 -49 73 -73 29 -41 30 -47 33 -172 l4 -130 -64
3 -63 3 -5 105 c-7 142 -28 170 -125 170 -35 0 -52 -6 -77 -29 l-33 -29 -3
-111 -4 -111 -64 0 -64 0 0 108 c1 159 36 232 134 279 44 21 62 24 134 20 72
-3 88 -7 124 -33z m790 -19 l-4 -55 -94 0 -94 0 0 -50 0 -49 73 -3 72 -3 0
-59 0 -59 -72 2 -73 2 0 -55 0 -55 93 -3 92 -3 3 -57 3 -58 -156 0 -155 0 0
280 0 280 158 0 157 0 -3 -55z m-4143 32 c15 -19 -10 -53 -33 -44 -16 6 -22
38 -9 50 10 11 30 8 42 -6z m-155 -23 c7 -19 -1 -34 -19 -34 -21 0 -28 19 -15
35 16 19 26 19 34 -1z m4876 -34 c0 -18 -23 -34 -34 -23 -11 11 -6 41 7 46 17
6 27 -1 27 -23z m-4810 -11 c0 -23 -27 -36 -42 -21 -5 5 -7 19 -4 30 8 26 46
18 46 -9z m4957 15 c3 -8 1 -20 -4 -25 -12 -12 -48 14 -39 29 10 17 36 15 43
-4z m-5157 -20 c0 -8 -7 -14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24 -19z
m5098 9 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z m242 -3
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-5046 -16 c9 -22 -1 -34 -30 -34 -26 0 -32 18 -12 38 17 17 35 15 42 -4z
m-184 -25 c0 -5 -9 -9 -20 -9 -19 0 -27 20 -13 33 7 8 33 -11 33 -24z m5140 1
c0 -11 -7 -20 -15 -20 -8 0 -15 7 -15 16 0 9 -5 12 -12 8 -7 -4 -8 -3 -4 4 12
20 46 14 46 -8z m-244 -11 c3 -6 0 -17 -7 -25 -18 -17 -44 -2 -36 20 7 18 33
21 43 5z m-4800 -20 c3 -6 0 -17 -7 -25 -18 -17 -44 -2 -36 20 7 18 33 21 43
5z m4864 -19 c0 -24 -26 -38 -42 -22 -16 16 -2 42 22 42 13 0 20 -7 20 -20z
m-5220 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m170 -16 c0 -23 -25 -27 -33 -6 -7 16 1 26 20 26 7 0 13 -9 13 -20z m270
5 c17 -20 5 -45 -20 -45 -23 0 -35 22 -26 45 7 19 30 19 46 0z m4865 9 c14 -6
13 -8 -6 -22 -18 -12 -23 -13 -30 -1 -18 28 -3 37 36 23z m-5193 -46 c-12 -12
-35 -1 -27 12 3 5 13 6 21 3 10 -4 12 -9 6 -15z m5038 8 c0 -24 -26 -38 -40
-22 -6 9 -9 20 -5 26 9 15 45 12 45 -4z m226 3 c10 -17 -13 -36 -27 -22 -12
12 -4 33 11 33 5 0 12 -5 16 -11z m-5116 -24 c0 -8 -7 -15 -15 -15 -8 0 -15 7
-15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m110 -30 c0 -18 -5 -25 -19 -25 -11
0 -23 5 -26 10 -11 18 3 40 25 40 15 0 20 -7 20 -25z m4868 7 c2 -7 -6 -12
-17 -12 -21 0 -27 11 -14 24 9 9 26 2 31 -12z m262 -4 c0 -16 -3 -19 -11 -11
-6 6 -8 16 -5 22 11 17 16 13 16 -11z m-5046 -4 c8 -21 -1 -34 -24 -34 -22 0
-34 15 -26 35 7 20 42 19 50 -1z m-164 -34 c0 -11 -7 -20 -15 -20 -8 0 -15 9
-15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m4847 4 c7 -19 -20 -49 -36 -40 -12
8 -15 39 -4 49 12 12 33 7 40 -9z m240 -28 c-4 -9 -11 -16 -17 -16 -11 0 -14
33 -3 44 11 10 26 -11 20 -28z m-98 -12 c-15 -19 -25 -18 -33 3 -8 21 14 36
31 21 10 -8 10 -14 2 -24z m-82 0 c3 -8 1 -20 -6 -27 -14 -14 -47 15 -37 32
10 16 36 13 43 -5z m-4977 -4 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1
10 9 10 9 0 16 -4 16 -10z m245 -21 c0 -17 -6 -24 -20 -24 -23 0 -33 34 -13
46 20 13 33 5 33 -22z m-87 4 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2
12 3 17 18 17 15 0 20 -5 18 -17z m5077 -23 c-5 -8 -11 -8 -17 -2 -6 6 -7 16
-3 22 5 8 11 8 17 2 6 -6 7 -16 3 -22z m-336 2 c13 -25 -2 -46 -28 -37 -22 7
-25 14 -15 39 8 21 31 20 43 -2z m-4811 -20 c3 -19 -1 -23 -15 -20 -10 2 -19
14 -21 26 -3 19 1 23 15 20 10 -2 19 -14 21 -26z m5025 -7 c1 -5 -6 -11 -15
-13 -11 -2 -18 3 -18 13 0 17 30 18 33 0z m-145 -16 c4 -19 -27 -34 -41 -20
-14 14 0 43 20 39 10 -2 19 -10 21 -19z m-4798 -4 c13 -16 6 -35 -15 -35 -18
0 -28 17 -21 35 7 19 20 19 36 0z m100 -20 c0 -20 -26 -25 -47 -9 -17 14 -17
14 1 29 21 16 46 5 46 -20z m-285 5 c0 -22 -29 -18 -33 3 -3 14 1 18 15 15 10
-2 18 -10 18 -18z m3325 -50 l0 -70 -65 0 -65 0 0 70 0 70 65 0 65 0 0 -70z
m370 0 l0 -71 -62 3 -63 3 -3 54 c-5 78 -2 81 68 81 l60 0 0 -70z m1365 60
c10 -16 -16 -43 -28 -31 -11 11 -2 41 12 41 5 0 13 -5 16 -10z m-147 -33 c3
-12 -3 -17 -22 -17 -25 0 -35 18 -19 34 12 12 38 2 41 -17z m262 -12 c0 -8 -4
-15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m-5046 -11 c8 -21 -1 -34
-25 -34 -34 0 -23 45 12 49 4 1 10 -6 13 -15z m64 -27 c2 -12 -3 -17 -17 -17
-15 0 -21 6 -21 21 0 25 33 22 38 -4z m100 -3 c4 -26 -20 -37 -44 -20 -24 18
-11 48 18 44 14 -2 24 -11 26 -24z m4680 -7 c2 -11 -3 -17 -17 -17 -23 0 -35
15 -26 31 10 15 39 6 43 -14z m-90 -18 c3 -17 -23 -33 -39 -23 -5 3 -9 14 -9
25 0 15 6 20 22 17 13 -2 24 -10 26 -19z m246 -11 c-9 -14 -23 2 -17 18 4 12
8 13 14 3 4 -7 5 -17 3 -21z m-5014 -3 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 -7 10 -15z m4949 -14 c7 -5 10 -14 7 -20 -10 -16
-43 -14 -49 3 -10 25 14 34 42 17z m-4839 -16 c15 -18 5 -35 -21 -35 -12 0
-19 7 -19 18 0 33 20 42 40 17z m86 -24 c-4 -6 -16 -11 -27 -11 -21 0 -27 35
-6 43 16 6 41 -18 33 -32z m4630 28 c9 -15 -11 -33 -30 -26 -9 4 -13 13 -10
22 7 17 30 20 40 4z m-92 -45 c19 -18 12 -34 -14 -34 -25 0 -35 17 -20 35 15
18 15 18 34 -1z m65 -18 c8 -10 8 -16 -2 -25 -16 -13 -27 -5 -27 21 0 21 13
23 29 4z m-4770 -20 c9 -10 8 -16 -4 -26 -19 -16 -41 1 -32 24 8 20 21 20 36
2z m209 -3 c-2 -11 -14 -19 -31 -21 -22 -3 -27 1 -27 17 0 17 6 21 31 21 24 0
30 -4 27 -17z m4664 -15 c-15 -15 -26 -4 -18 18 5 13 9 15 18 6 9 -9 9 -15 0
-24z m-4777 1 c0 -7 -8 -15 -17 -17 -18 -3 -25 18 -11 32 10 10 28 1 28 -15z
m-255 -5 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m4839 -3
c17 -11 7 -41 -13 -41 -19 0 -28 14 -20 34 7 18 13 19 33 7z m95 -37 c8 -21
-1 -34 -25 -34 -12 0 -19 7 -19 18 0 34 33 45 44 16z m-4537 -5 c16 -16 5 -39
-17 -39 -25 0 -35 18 -20 35 15 18 22 19 37 4z m4813 1 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-5014 -11 c10 -17 -13
-36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z m114 -18 c0 -11 -5 -23 -11
-27 -15 -9 -33 12 -26 30 9 23 37 20 37 -3z m4716 8 c10 -17 -13 -36 -27 -22
-12 12 -4 33 11 33 5 0 12 -5 16 -11z m-206 -24 c15 -18 6 -45 -15 -45 -18 0
-30 24 -21 45 7 19 20 19 36 0z m-4390 -36 c0 -15 -30 -23 -42 -11 -6 6 -8 18
-4 27 4 13 10 14 26 5 11 -6 20 -15 20 -21z m-310 1 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m208 -18 c3 -17 -2 -22 -17
-22 -14 0 -21 6 -21 18 0 38 33 41 38 4z m4574 -14 c-16 -16 -35 3 -26 26 6
16 8 16 22 2 13 -12 14 -18 4 -28z m163 22 c11 -17 -5 -32 -21 -19 -7 6 -11
15 -8 20 7 12 21 11 29 -1z m-4855 -15 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8
23 11 12 23 8 23 -8z m4587 -24 c0 -23 -16 -41 -33 -34 -17 6 -19 40 -1 46 23
7 34 4 34 -12z m-4637 -1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m275 -10 c10 -16 -5 -50 -21 -50 -17 0 -28 24 -20 45 6
17 32 20 41 5z m4505 -20 c0 -25 -36 -47 -37 -22 -2 28 6 42 22 42 8 0 15 -9
15 -20z m-4702 -18 c2 -12 -1 -22 -6 -22 -15 0 -34 28 -27 40 10 17 30 6 33
-18z m4612 2 c0 -8 -9 -14 -20 -14 -22 0 -23 1 -14 24 7 18 34 10 34 -10z
m-4500 1 c15 -18 5 -35 -21 -35 -12 0 -19 7 -19 18 0 33 20 42 40 17z m4410
-20 c13 -16 6 -35 -15 -35 -18 0 -28 17 -21 35 7 19 20 19 36 0z m-4292 -36
c3 -17 -23 -33 -39 -23 -5 3 -9 14 -9 25 0 15 6 20 22 17 13 -2 24 -10 26 -19z
m-308 -4 c0 -8 -6 -15 -14 -15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m108 -8
c2 -10 -3 -17 -11 -17 -16 0 -30 24 -19 34 10 10 27 1 30 -17z m4582 -2 c0
-15 -27 -28 -41 -19 -13 8 -11 32 4 37 18 6 37 -3 37 -18z m178 -8 c2 -10 -3
-17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28 -4z m-4650 -4 c-5 -25 -28 -28
-28 -4 0 12 6 21 16 21 9 0 14 -7 12 -17z m4546 9 c8 -13 -11 -35 -23 -28 -16
10 -14 36 3 36 8 0 17 -4 20 -8z m-184 -22 c0 -24 -26 -38 -42 -22 -16 16 -2
42 22 42 13 0 20 -7 20 -20z m-4243 -11 c17 -17 4 -39 -22 -39 -13 0 -25 6
-28 14 -6 15 11 36 28 36 6 0 16 -5 22 -11z m-360 -5 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m200 -30 c8 -21 -13 -42 -28 -27 -13 13 -5 43
11 43 6 0 13 -7 17 -16z m69 -23 c-11 -18 -36 -13 -36 7 0 10 3 22 7 26 10 10
37 -20 29 -33z m4372 6 c-2 -11 -9 -22 -15 -25 -16 -4 -27 22 -19 43 8 22 38
6 34 -18z m-4533 5 c0 -18 -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13 -10 13 -16z
m4445 3 c16 -20 6 -45 -19 -45 -23 0 -32 18 -19 43 11 21 22 22 38 2z m276
-17 c4 -7 4 -10 -1 -6 -4 4 -14 0 -21 -10 -12 -15 -15 -15 -23 -2 -5 8 -7 18
-5 22 8 12 42 9 50 -4z m-4428 -26 c3 -16 -2 -22 -17 -22 -24 0 -35 15 -27 36
9 23 40 13 44 -14z m4338 9 c0 -15 -3 -17 -13 -9 -7 6 -13 14 -13 19 0 5 6 9
13 9 7 0 13 -9 13 -19z m-4428 -29 c2 -14 -2 -22 -12 -22 -19 0 -29 17 -22 36
8 22 30 12 34 -14z m4666 7 c-8 -14 -24 -10 -24 6 0 9 6 12 15 9 8 -4 12 -10
9 -15z m-453 -43 c-16 -19 -41 -10 -41 14 0 24 21 31 39 14 11 -10 12 -16 2
-28z m-4481 12 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6 0 10 -10 10 -22z
m190 -4 c0 -8 -7 -14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24 -19z m4388 3
c3 -12 -3 -17 -22 -17 -25 0 -35 18 -19 34 12 12 38 2 41 -17z m-4183 -12 c0
-11 -8 -21 -17 -23 -14 -3 -18 3 -18 23 0 20 4 26 18 23 9 -2 17 -12 17 -23z
m4255 10 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15
-15z m-3156 -67 c20 -71 9 -96 -23 -57 l-21 24 -6 -22 c-7 -24 -34 -32 -34
-10 0 14 29 117 36 129 12 19 33 -9 48 -64z m154 49 c2 -10 -3 -17 -12 -17
-13 0 -16 -12 -16 -56 0 -47 -3 -55 -17 -52 -14 3 -18 14 -18 50 0 35 -5 51
-19 61 -28 20 -13 34 36 33 32 -1 44 -6 46 -19z m208 11 c9 13 16 14 24 6 9
-9 8 -19 -4 -42 -9 -17 -16 -47 -16 -67 0 -24 -4 -35 -15 -35 -10 0 -15 10
-15 30 0 17 -7 47 -15 66 -17 42 -10 64 13 40 14 -14 17 -14 28 2z m168 -4
c10 -9 16 -33 16 -59 0 -64 -33 -90 -81 -65 -26 15 -28 114 -1 129 25 15 48
13 66 -5z m104 -42 c2 -36 7 -52 17 -52 10 0 15 16 17 53 2 38 7 52 18 52 16
0 26 -69 16 -111 -7 -26 -48 -39 -76 -24 -17 9 -20 21 -20 76 0 48 3 65 13 62
7 -3 13 -26 15 -56z m204 41 c5 -2 7 -33 6 -67 -1 -50 -5 -63 -17 -62 -8 2
-18 11 -21 21 -6 17 -7 17 -12 -3 -9 -31 -28 -27 -29 6 0 15 -2 43 -3 62 -3
46 10 61 43 54 14 -4 29 -9 33 -11z m239 7 c29 -16 25 -44 -6 -36 -32 8 -33
-2 -1 -13 32 -13 40 -47 15 -72 -22 -22 -38 -24 -64 -6 -25 19 -10 38 23 31
l27 -6 -25 20 c-13 11 -27 19 -30 17 -3 -2 -7 9 -8 25 -3 41 30 60 69 40z
m157 -12 c3 -14 -3 -18 -22 -18 -14 0 -26 -4 -26 -10 0 -5 9 -10 20 -10 11 0
20 -7 20 -15 0 -8 -9 -15 -20 -15 -11 0 -20 -4 -20 -10 0 -5 12 -10 26 -10 19
0 25 -4 22 -17 -2 -13 -13 -18 -38 -18 l-35 0 -3 59 c-2 32 -1 65 2 72 7 19
70 13 74 -8z m135 3 c10 -10 12 -64 3 -79 -4 -7 -2 -21 4 -32 9 -17 8 -20 -9
-20 -12 0 -23 9 -27 22 l-7 23 -8 -23 c-17 -44 -29 -22 -29 53 l0 75 33 -5
c17 -4 36 -10 40 -14z m103 -27 l11 -39 7 37 c4 21 11 40 17 44 23 14 27 -14
10 -76 -24 -87 -45 -91 -66 -13 -9 32 -18 63 -20 70 -2 6 -1 14 3 17 13 13 27
-2 38 -40z m144 -29 c0 -60 -3 -75 -15 -75 -12 0 -15 14 -15 68 0 38 3 72 7
75 17 18 23 1 23 -68z m150 60 c15 -9 12 -45 -4 -45 -8 0 -16 5 -18 12 -2 6
-11 9 -20 6 -19 -8 -12 -68 8 -68 8 0 14 7 14 15 0 22 27 18 33 -5 11 -42 -71
-64 -95 -26 -12 17 -9 101 3 113 11 11 60 10 79 -2z m148 -8 c3 -12 -3 -17
-22 -17 -14 0 -26 -4 -26 -10 0 -5 9 -10 20 -10 11 0 20 -7 20 -15 0 -8 -9
-15 -20 -15 -11 0 -20 -4 -20 -10 0 -5 12 -10 26 -10 19 0 25 -5 22 -17 -2
-13 -14 -19 -41 -21 l-37 -3 0 76 0 76 37 -3 c26 -3 39 -9 41 -21z m-3099 -10
c13 -16 5 -27 -21 -27 -18 0 -25 20 -11 33 10 10 19 8 32 -6z m-389 -7 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m4605 -21
c0 -8 -10 -15 -22 -17 -19 -3 -23 1 -23 22 0 22 3 24 23 17 12 -5 22 -15 22
-22z m-4380 -9 c-5 -8 -11 -8 -17 -2 -6 6 -7 16 -3 22 5 8 11 8 17 2 6 -6 7
-16 3 -22z m305 1 c0 -20 -39 -36 -53 -22 -14 14 5 41 29 41 17 0 24 -6 24
-19z m3990 -16 c0 -17 -36 -20 -45 -5 -4 6 -4 18 -1 26 4 12 10 13 26 4 11 -6
20 -17 20 -25z m-4216 19 c23 -9 20 -34 -4 -34 -13 0 -20 7 -20 20 0 22 1 23
24 14z m4394 -22 c2 -14 -2 -22 -12 -22 -19 0 -29 17 -22 36 8 22 30 12 34
-14z m112 7 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m-4390
-24 c15 -18 6 -45 -15 -45 -18 0 -30 24 -21 45 7 19 20 19 36 0z m-220 -10 c0
-8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m367
-1 c3 -8 -2 -23 -11 -32 -15 -15 -17 -15 -32 0 -21 21 -11 48 16 48 11 0 23
-7 27 -16z m3988 -14 c0 -8 -10 -16 -22 -18 -18 -3 -23 2 -23 18 0 16 5 21 23
18 12 -2 22 -10 22 -18z m-95 -35 c-12 -15 -15 -14 -26 7 -15 28 3 45 25 23
12 -12 12 -17 1 -30z m195 24 c0 -7 -8 -15 -17 -17 -18 -3 -25 18 -11 32 10
10 28 1 28 -15z m-4307 -32 c2 -11 -3 -17 -17 -17 -23 0 -35 15 -26 31 10 15
39 6 43 -14z m127 -3 c0 -12 -8 -20 -22 -22 -17 -3 -23 2 -23 16 0 20 16 33
35 28 6 -2 10 -11 10 -22z m4383 14 c-7 -19 -38 -22 -38 -4 0 10 9 16 21 16
12 0 19 -5 17 -12z m-4588 -23 c0 -19 -20 -29 -32 -17 -15 15 -2 43 17 36 8
-3 15 -12 15 -19z m4468 6 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9
9 7 -2 15 -10 17 -17z m-4120 -23 c-3 -25 -34 -36 -50 -20 -17 17 -1 42 27 42
22 0 26 -4 23 -22z m3992 -2 c0 -7 -7 -19 -15 -26 -19 -16 -41 1 -32 24 8 20
47 21 47 2z m-92 -13 c5 -17 -26 -29 -40 -15 -6 6 -7 15 -3 22 9 14 37 9 43
-7z m-4099 -29 c-15 -18 -28 -18 -36 2 -8 20 17 36 34 22 10 -8 10 -14 2 -24z
m4001 12 c0 -18 -6 -25 -22 -28 -15 -2 -23 -10 -23 -23 0 -13 -7 -20 -20 -20
-13 0 -20 7 -20 20 0 13 7 20 20 20 16 0 19 6 17 28 -2 23 1 27 23 27 20 0 25
-5 25 -24z m370 3 c0 -5 -10 -9 -21 -9 -18 0 -19 2 -9 15 12 15 30 12 30 -6z
m-4282 -20 c4 -19 -27 -34 -41 -20 -14 14 0 43 20 39 10 -2 19 -10 21 -19z
m4172 -4 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m-4035
-30 c0 -13 -8 -21 -22 -23 -19 -3 -23 1 -23 23 0 22 4 26 23 23 14 -2 22 -10
22 -23z m-187 -23 c3 -17 -2 -22 -17 -22 -16 0 -21 6 -21 26 0 33 33 30 38 -4z
m4010 10 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m-3898
-23 c0 -15 -30 -23 -42 -11 -6 6 -8 18 -4 27 4 13 10 14 26 5 11 -6 20 -15 20
-21z m-215 -9 c-5 -8 -11 -8 -17 -2 -6 6 -7 16 -3 22 5 8 11 8 17 2 6 -6 7
-16 3 -22z m-107 3 c-4 -22 -22 -20 -26 1 -2 10 3 16 13 16 10 0 15 -7 13 -17z
m467 -8 c0 -21 -31 -33 -46 -18 -5 5 -7 17 -3 27 9 24 49 17 49 -9z m3695 0
c0 -18 -15 -26 -34 -19 -12 5 -13 10 -6 25 11 20 40 16 40 -6z m-107 9 c18 -6
11 -44 -9 -44 -17 0 -27 17 -20 35 6 17 8 17 29 9z m297 -9 c0 -8 -4 -15 -10
-15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-3970 -35 c0 -23
-13 -32 -34 -24 -19 7 -21 25 -5 41 16 16 39 5 39 -17z m3898 18 c-6 -18 -28
-21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m202 5 c0 -5 -4 -14 -9 -21 -7
-11 -9 -11 -14 1 -3 8 -3 18 0 21 9 8 23 7 23 -1z m-4212 -26 c2 -12 -3 -17
-17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m-104 -18 c-8 -14 -34 -11 -34 4
0 8 3 17 7 20 9 9 34 -13 27 -24z m346 15 c11 -12 10 -18 -3 -31 -8 -9 -23
-13 -31 -10 -19 8 -21 43 -3 50 20 9 24 8 37 -9z m3494 0 c23 -9 20 -44 -3
-44 -24 0 -33 13 -25 34 7 18 7 18 28 10z m109 -18 c7 -17 -1 -26 -24 -26 -21
0 -33 15 -24 30 9 15 42 12 48 -4z m-4013 -21 c10 -13 9 -15 -9 -15 -12 0 -21
6 -21 15 0 8 4 15 9 15 5 0 14 -7 21 -15z m4118 -18 c2 -11 -3 -17 -16 -17
-26 0 -34 11 -20 27 14 17 32 12 36 -10z m168 -4 c-10 -10 -19 5 -10 18 6 11
8 11 12 0 2 -7 1 -15 -2 -18z m-3951 -3 c0 -8 -10 -16 -22 -18 -22 -3 -32 16
-16 32 12 11 38 2 38 -14z m-530 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6
2 10 4 10 3 0 8 -4 11 -10z m323 -7 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16
0 18 31 15 38 -3z m100 -6 c3 -14 -1 -18 -20 -15 -12 2 -24 11 -26 21 -3 14 1
18 20 15 12 -2 24 -11 26 -21z m242 -2 c0 -29 -34 -36 -44 -9 -8 21 1 34 25
34 14 0 19 -7 19 -25z m3388 3 c-2 -13 -10 -23 -18 -23 -8 0 -16 10 -18 23 -3
17 2 22 18 22 16 0 21 -5 18 -22z m90 -16 c2 -14 -2 -22 -12 -22 -19 0 -29 17
-22 36 8 22 30 12 34 -14z m232 13 c0 -8 -6 -15 -14 -15 -17 0 -28 14 -19 24
12 12 33 6 33 -9z m-3805 -31 c0 -10 -8 -20 -18 -22 -22 -4 -35 27 -16 39 20
12 34 5 34 -17z m3658 -8 c7 -18 -1 -26 -25 -26 -18 0 -25 20 -11 33 11 12 31
8 36 -7z m-4113 -6 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6
0 10 -4 10 -10z m610 -3 c0 -17 -20 -37 -37 -37 -7 0 -13 11 -13 25 0 20 5 25
25 25 14 0 25 -6 25 -13z m3274 -23 c7 -18 -21 -31 -36 -16 -15 15 -2 43 16
36 8 -3 17 -12 20 -20z m-3629 -4 c0 -22 -29 -18 -33 3 -3 14 1 18 15 15 10
-2 18 -10 18 -18z m118 6 c7 -18 -11 -36 -29 -29 -8 3 -14 14 -14 24 0 22 35
26 43 5z m3635 -8 c3 -13 -3 -18 -22 -18 -20 0 -26 5 -26 21 0 15 5 20 22 17
13 -2 24 -11 26 -20z m212 1 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12
10 9 6 -3 10 -10 10 -16z m-4072 -13 c3 -12 -1 -17 -10 -14 -7 3 -15 13 -16
22 -3 12 1 17 10 14 7 -3 15 -13 16 -22z m3916 18 c21 -8 20 -30 -1 -38 -21
-8 -38 8 -30 28 7 18 9 18 31 10z m-3536 -27 c4 -20 -25 -34 -40 -19 -15 15
-1 44 19 40 10 -2 19 -11 21 -21z m120 -20 c4 -20 -25 -34 -40 -19 -15 15 -1
44 19 40 10 -2 19 -11 21 -21z m3179 7 c8 -21 -13 -42 -28 -27 -13 13 -5 43
11 43 6 0 13 -7 17 -16z m-3403 -12 c8 -13 -11 -35 -23 -28 -16 10 -14 36 3
36 8 0 17 -4 20 -8z m295 -15 c15 -19 -10 -53 -33 -44 -23 9 -21 57 3 57 10 0
24 -6 30 -13z m3211 -6 c0 -11 -5 -23 -11 -27 -15 -9 -33 12 -26 30 9 23 37
20 37 -3z m-3622 -9 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z
m3842 3 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m-3584 -6
c10 -17 -23 -46 -37 -32 -7 7 -9 19 -6 27 7 18 33 21 43 5z m3208 -4 c3 -9 -1
-18 -10 -22 -19 -7 -39 11 -30 26 10 16 33 13 40 -4z m456 5 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-374 -39 c-10 -15
-46 -14 -46 2 0 7 5 18 12 25 10 10 15 9 26 -2 8 -8 11 -19 8 -25z m468 18
c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z
m-3856 0 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5
13 -11z m312 -14 c16 -19 4 -35 -26 -35 -38 0 -31 44 7 49 4 1 12 -6 19 -14z
m3235 5 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9
-10z m-3770 -9 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z
m135 -17 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z m260 -21
c0 -34 -41 -28 -50 8 -7 23 -5 25 21 21 23 -3 29 -9 29 -29z m3432 22 c0 -5
-5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-372
-20 c0 -26 -18 -33 -37 -14 -16 16 -5 39 18 39 14 0 19 -7 19 -25z m250 -5 c0
-11 -6 -20 -14 -20 -18 0 -29 16 -21 30 11 18 35 11 35 -10z m214 -1 c-8 -14
-24 -10 -24 6 0 9 6 12 15 9 8 -4 12 -10 9 -15z m-3637 -5 c8 -21 -13 -42 -28
-27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z m328 -24 c0 -18 -6 -26 -23 -28 -24
-4 -38 18 -28 44 3 9 15 14 28 12 17 -2 23 -10 23 -28z m2959 -1 c-3 -5 -14
-9 -25 -9 -21 0 -26 26 -6 33 15 5 39 -12 31 -24z m-176 -8 c-2 -9 -13 -17
-26 -19 -16 -3 -22 2 -22 16 0 11 6 22 13 25 20 7 38 -4 35 -22z m252 9 c0
-13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33 -13z m118 -2 c5 -25
-34 -24 -41 1 -3 14 1 18 17 17 11 -1 22 -9 24 -18z m-3299 3 c17 -11 7 -41
-13 -41 -19 0 -29 17 -22 35 6 17 15 18 35 6z m3421 -10 c0 -6 -4 -13 -10 -16
-5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-3830 -26 c0 -8 -7 -15
-15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m145 5 c3 -6 -1
-13 -10 -16 -19 -8 -30 0 -20 15 8 14 22 14 30 1z m473 -16 c4 -27 -26 -40
-46 -20 -21 20 -8 48 20 44 14 -2 24 -11 26 -24z m2820 3 c2 -12 -3 -17 -17
-17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z m-3148 4 c0 -13 -26 -32 -33 -24
-14 13 -6 33 13 33 11 0 20 -4 20 -9z m188 -13 c-2 -13 -10 -23 -18 -23 -8 0
-16 10 -18 23 -3 17 2 22 18 22 16 0 21 -5 18 -22z m2789 -4 c3 -8 1 -20 -6
-27 -14 -14 -47 15 -37 32 10 16 36 13 43 -5z m250 -18 c-9 -23 -37 -20 -37 3
0 21 20 33 34 20 5 -4 6 -15 3 -23z m172 20 c9 -11 8 -15 -5 -20 -23 -9 -24
-8 -24 14 0 23 13 26 29 6z m-3455 -27 c-8 -14 -34 -11 -34 4 0 8 3 17 7 20 9
9 34 -13 27 -24z m310 -5 c8 -21 -1 -34 -24 -34 -10 0 -21 4 -24 9 -10 16 5
41 24 41 10 0 21 -7 24 -16z m134 4 c14 -14 -2 -48 -24 -48 -20 0 -29 27 -14
45 15 18 22 19 38 3z m2702 -8 c0 -24 -26 -38 -42 -22 -8 8 -8 15 2 27 18 21
40 19 40 -5z m-3002 -7 c5 -17 -26 -29 -40 -15 -6 6 -7 15 -3 22 9 14 37 9 43
-7z m2831 -2 c12 -21 5 -31 -21 -31 -23 0 -36 29 -17 41 20 13 27 11 38 -10z
m381 -17 c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z m-3290 -3
c0 -14 -18 -23 -30 -16 -6 4 -8 11 -5 16 8 12 35 12 35 0z m2998 -13 c4 -19
-20 -29 -38 -18 -11 7 -12 11 0 25 15 18 34 15 38 -7z m-2860 -5 c5 -17 -26
-29 -40 -15 -6 6 -7 15 -3 22 9 14 37 9 43 -7z m310 -5 c4 -23 -36 -45 -44
-23 -9 23 4 47 23 43 10 -2 19 -11 21 -20z m2387 -2 c0 -22 -14 -29 -34 -17
-19 12 -6 43 16 39 10 -2 18 -12 18 -22z m-2507 -3 c5 -17 -26 -29 -40 -15 -6
6 -7 15 -3 22 9 14 37 9 43 -7z m2817 8 c3 -5 1 -13 -5 -16 -15 -9 -43 3 -35
15 8 13 32 13 40 1z m-2626 -25 c17 -20 3 -46 -24 -46 -30 0 -39 14 -25 40 12
23 33 26 49 6z m-614 -5 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4
15 -9z m2853 -13 c-2 -15 -10 -23 -23 -23 -13 0 -21 8 -23 23 -3 18 1 22 23
22 22 0 26 -4 23 -22z m172 -4 c0 -20 -29 -30 -43 -15 -9 10 -8 16 7 26 21 16
36 11 36 -11z m116 -2 c-22 -5 -30 3 -21 18 5 8 11 7 22 -2 15 -12 14 -13 -1
-16z m-2817 9 c17 -11 7 -41 -13 -41 -15 0 -29 27 -20 41 7 11 15 11 33 0z
m2991 -6 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
-15z m-2825 -24 c0 -22 -34 -39 -44 -22 -5 8 -7 20 -4 28 7 18 48 13 48 -6z
m183 -9 c3 -17 -2 -22 -18 -22 -25 0 -38 29 -19 41 20 13 34 6 37 -19z m2110
0 c3 -16 -2 -22 -16 -22 -24 0 -35 23 -19 39 16 16 31 8 35 -17z m599 11 c4
-11 -26 -27 -35 -19 -2 3 -2 12 2 21 6 18 27 16 33 -2z m-429 -21 c2 -14 -2
-22 -12 -22 -19 0 -29 17 -22 36 8 22 30 12 34 -14z m-2398 -2 c0 -21 -33 -29
-45 -10 -9 15 4 30 26 30 12 0 19 -7 19 -20z m2530 0 c0 -11 -6 -20 -14 -20
-18 0 -29 16 -21 30 11 18 35 11 35 -10z m-2680 -16 c0 -17 -26 -19 -36 -3 -7
12 15 31 28 23 4 -3 8 -12 8 -20z m355 1 c0 -22 -31 -33 -47 -17 -17 17 -1 44
24 40 15 -2 23 -10 23 -23z m-530 -5 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18
18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m2490 -5 c0 -30 -39 -27 -43 3 -3 20
0 23 20 20 15 -2 23 -10 23 -23z m-2738 -7 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m651 -1 c2 -9 -4 -20 -13 -23 -20 -8 -50 19 -40 36 10 17
49 8 53 -13z m2242 -2 c0 -15 -27 -28 -41 -19 -13 8 -11 32 4 37 18 6 37 -3
37 -18z m375 15 c3 -5 4 -14 0 -20 -8 -13 -35 -1 -35 16 0 16 26 19 35 4z
m-3115 -20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m2508 -12 c-2 -13 -11 -24 -21 -26 -22 -4 -35 27 -16 39 25 16 41 10
37 -13z m-2353 3 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z
m195 -4 c0 -19 -10 -27 -26 -20 -21 8 -17 33 6 33 11 0 20 -6 20 -13z m340 -7
c0 -24 -26 -38 -42 -22 -16 16 -2 42 22 42 13 0 20 -7 20 -20z m2190 0 c0 -11
-7 -20 -15 -20 -15 0 -21 21 -8 33 12 13 23 7 23 -13z m320 4 c0 -8 -4 -12
-10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-2641 -3 c19 -12
6 -43 -16 -39 -21 4 -31 48 -11 48 7 0 19 -4 27 -9z m2461 -6 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-2252 -22 c-3
-31 -34 -44 -44 -19 -9 24 3 46 26 46 17 0 21 -5 18 -27z m1672 7 c0 -13 -7
-20 -18 -20 -24 0 -32 8 -25 26 8 22 43 17 43 -6z m230 0 c0 -21 -33 -29 -45
-10 -9 15 4 30 26 30 12 0 19 -7 19 -20z m-2233 -6 c8 -21 -13 -42 -28 -27
-13 13 -5 43 11 43 6 0 13 -7 17 -16z m2353 2 c0 -8 -4 -18 -10 -21 -5 -3 -10
-4 -10 -1 0 2 -3 11 -6 20 -4 11 -1 16 10 16 9 0 16 -6 16 -14z m188 -4 c2 -7
-6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2 31 -12z m-2133 -22 c0 -16 -6
-26 -18 -28 -19 -4 -32 20 -23 44 9 25 41 13 41 -16z m-645 6 c0 -16 -18 -31
-27 -22 -8 8 5 36 17 36 5 0 10 -6 10 -14z m2158 -11 c4 -20 -15 -36 -35 -28
-17 6 -17 40 0 46 19 8 32 2 35 -18z m235 5 c4 -16 0 -20 -19 -20 -13 0 -24 6
-24 13 0 32 35 37 43 7z m547 4 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5
9 10 9 6 0 10 -7 10 -16z m-2507 -8 c8 -20 -20 -43 -35 -28 -14 14 -3 42 16
42 7 0 16 -6 19 -14z m297 -5 c0 -27 -19 -36 -37 -18 -18 18 -9 37 18 37 12 0
19 -7 19 -19z m1348 -14 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19
-11 21 -21z m476 8 c-4 -8 -15 -15 -25 -15 -12 0 -16 5 -13 15 4 8 15 15 25
15 12 0 16 -5 13 -15z m291 5 c-3 -5 -15 -10 -26 -10 -11 0 -17 5 -14 10 3 6
15 10 26 10 11 0 17 -4 14 -10z m-2709 -11 c10 -17 -13 -36 -27 -22 -12 12 -4
33 11 33 5 0 12 -5 16 -11z m648 5 c17 -7 22 -39 7 -48 -25 -16 -55 26 -34 47
8 8 8 8 27 1z m-264 -18 c0 -22 -19 -30 -35 -16 -22 18 -18 30 10 30 15 0 25
-6 25 -14z m1795 -7 c0 -7 -8 -15 -17 -17 -18 -3 -25 18 -11 32 10 10 28 1 28
-15z m-1958 -23 c-9 -23 -37 -20 -37 3 0 21 20 33 34 20 5 -4 6 -15 3 -23z
m521 7 c-3 -32 -32 -43 -42 -17 -7 19 10 44 31 44 10 0 13 -9 11 -27z m1122 6
c0 -25 -33 -22 -38 4 -2 12 3 17 17 17 15 0 21 -6 21 -21z m450 6 c0 -8 -7
-15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m184 -6 c-3 -5 -10 -7 -15
-3 -5 3 -7 10 -3 15 3 5 10 7 15 3 5 -3 7 -10 3 -15z m-694 -4 c15 -17 5 -35
-20 -35 -22 0 -33 23 -17 39 15 15 22 14 37 -4z m287 -19 c-4 -9 -11 -16 -17
-16 -17 0 -24 31 -9 41 18 11 34 -5 26 -25z m-1967 14 c0 -5 -4 -10 -9 -10 -6
0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m335 -25 c0 -28 -29 -25 -33
3 -3 19 0 23 15 20 10 -2 18 -12 18 -23z m72 9 c7 -18 -14 -35 -34 -27 -7 3
-13 14 -13 24 0 24 38 26 47 3z m288 -15 c0 -31 -31 -38 -40 -8 -5 14 -2 25 6
30 21 13 34 5 34 -22z m903 7 c6 -27 -11 -38 -33 -22 -17 12 -17 16 -6 30 17
20 35 16 39 -8z m299 8 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7
17 -16z m-1967 -9 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0
15 -7 15 -15z m850 -1 c11 -12 10 -18 -3 -32 -16 -15 -18 -15 -34 0 -13 14
-14 20 -3 32 7 9 16 16 20 16 4 0 13 -7 20 -16z m1027 5 c15 -15 5 -39 -17
-39 -22 0 -32 24 -17 39 6 6 14 11 17 11 3 0 11 -5 17 -11z m-1302 -24 c0 -13
-8 -21 -22 -23 -17 -3 -23 2 -23 16 0 10 3 22 7 26 13 13 38 1 38 -19z m1010
0 c0 -13 -8 -21 -22 -23 -17 -3 -23 2 -23 16 0 10 3 22 7 26 13 13 38 1 38
-19z m-1335 -6 c0 -14 -5 -19 -17 -17 -26 5 -29 38 -4 38 15 0 21 -6 21 -21z
m676 6 c8 -20 -13 -45 -32 -38 -14 5 -19 34 -7 46 11 12 33 7 39 -8z m573 2
c8 -10 7 -18 -2 -31 -13 -17 -14 -17 -31 -1 -13 13 -14 20 -5 31 14 17 24 18
38 1z m681 -13 c0 -8 -9 -14 -20 -14 -19 0 -27 19 -14 33 10 9 34 -4 34 -19z
m120 5 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m-1292 -5
c-5 -25 -35 -41 -49 -27 -18 18 -3 43 26 43 19 0 26 -5 23 -16z m96 1 c3 -8
-2 -22 -11 -30 -14 -15 -18 -15 -31 -1 -8 8 -12 22 -9 30 8 20 43 21 51 1z
m71 5 c10 -16 -5 -50 -21 -50 -17 0 -28 24 -20 45 6 17 32 20 41 5z m234 -4
c17 -20 -13 -43 -34 -26 -12 10 -13 16 -4 26 6 8 15 14 19 14 4 0 13 -6 19
-14z m1093 -1 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2
11 -8 11 -13z m-1247 -14 c0 -8 -8 -19 -17 -25 -22 -13 -44 5 -34 29 7 20 51
16 51 -4z m75 10 c0 -20 -13 -41 -25 -41 -15 0 -24 19 -17 38 5 14 42 17 42 3z
m-1023 -27 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m331 9 c-2
-10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z
m80 -6 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11 21 -21z m1080
6 c-5 -26 -38 -29 -38 -4 0 15 6 21 21 21 14 0 19 -5 17 -17z m-1660 -26 c2
-10 -3 -17 -12 -17 -18 0 -29 16 -21 31 9 14 29 6 33 -14z m660 0 c4 -20 -25
-34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11 21 -21z m920 1 c-2 -13 -10 -23
-18 -23 -8 0 -16 10 -18 23 -3 17 2 22 18 22 16 0 21 -5 18 -22z m230 -1 c2
-12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m100 6 c-2 -10 -10
-18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m-2108 -3
c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m475
-10 c0 -22 -29 -18 -33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z m1135 -11 c0
-12 -7 -19 -19 -19 -22 0 -34 23 -19 38 15 15 38 3 38 -19z m85 11 c0 -8 -10
-16 -22 -18 -18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z m779
11 c3 -5 0 -11 -8 -14 -15 -6 -26 1 -26 15 0 11 27 10 34 -1z m-2559 -11 c3
-5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1035 -9
c0 -19 -23 -35 -34 -24 -14 15 -5 43 14 43 13 0 20 -7 20 -19z m155 -7 c0 -10
-8 -20 -17 -22 -18 -3 -26 27 -11 42 12 11 28 0 28 -20z m-555 1 c0 -8 -7 -15
-15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m1656 -14 c-10
-16 -36 -13 -36 3 0 17 21 27 34 16 4 -5 5 -13 2 -19z m-2166 3 c0 -8 -5 -12
-10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m253 1 c1 -5 -6 -11 -15 -13 -11
-2 -18 3 -18 13 0 17 30 18 33 0z m345 -8 c2 -12 -3 -17 -17 -17 -12 0 -21 6
-21 13 0 31 32 34 38 4z m552 3 c0 -24 -26 -38 -42 -22 -8 8 -8 15 2 27 18 21
40 19 40 -5z m113 6 c8 -20 -20 -43 -35 -28 -14 14 -3 42 16 42 7 0 16 -6 19
-14z m145 -3 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15
0 20 -5 18 -17z m448 6 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5
16 -11z m-876 -18 c0 -11 -5 -23 -11 -27 -15 -9 -33 12 -26 30 9 23 37 20 37
-3z m514 11 c2 -4 1 -14 -4 -22 -7 -11 -11 -11 -24 -1 -9 7 -13 17 -10 22 7
11 31 12 38 1z m-814 -23 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24
-14z m643 7 c7 -18 -11 -36 -29 -29 -21 8 -17 43 5 43 10 0 21 -6 24 -14z
m457 -6 c0 -18 -3 -19 -20 -10 -11 6 -20 15 -20 20 0 6 9 10 20 10 13 0 20 -7
20 -20z m-1440 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m1377 -6 c9 -23 -13 -40 -32 -24 -12 10 -13 16 -4 26 15 18 28
18 36 -2z m313 -13 c0 -18 -18 -21 -30 -6 -10 13 -9 15 9 15 11 0 21 -4 21 -9z
m233 2 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m92 -3 c3 -5 -1
-10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-1909 -11 c10
-17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z m319 -19 c0 -22
-29 -18 -33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z m252 4 c8 -20 -8 -36
-26 -25 -15 10 -8 41 9 41 6 0 13 -7 17 -16z m428 -4 c0 -22 -29 -18 -33 3 -3
14 1 18 15 15 10 -2 18 -10 18 -18z m185 5 c10 -12 10 -19 2 -27 -15 -15 -48
14 -38 31 10 15 21 14 36 -4z m-779 -10 c-1 -15 -24 -12 -29 3 -3 9 2 13 12
10 10 -1 17 -7 17 -13z m499 -11 c0 -8 -7 -14 -15 -14 -15 0 -21 21 -9 33 10
9 24 -2 24 -19z m-1070 6 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m253 4 c11 -11 -3 -24 -18 -18 -8 4 -12 10 -9 15 6 11
18 12 27 3z m592 -3 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15
-9z m411 -2 c3 -6 2 -15 -2 -19 -12 -12 -38 5 -30 19 9 14 23 14 32 0z m-256
-19 c0 -11 -4 -20 -9 -20 -13 0 -32 26 -24 33 13 14 33 6 33 -13z m-627 -5 c4
-8 2 -17 -3 -20 -13 -8 -40 6 -40 21 0 19 36 18 43 -1z m532 5 c3 -5 -1 -10
-9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-755 -25 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m1435 5 c3
-5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-125
-15 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m-1020 -5 c0
-5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m212
-5 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z
m-353 -4 c9 -6 10 -10 2 -16 -15 -8 -41 1 -41 15 0 12 20 13 39 1z m457 -2 c8
-14 -11 -33 -25 -25 -11 7 -4 36 9 36 5 0 12 -5 16 -11z m94 -4 c0 -8 -7 -15
-15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m200 0 c0 -8 -9
-15 -20 -15 -11 0 -20 7 -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z m-480 -5
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m658 -2 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m322 -8 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1088 -45
c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z
m-102 -5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m193 4 c11 -11 -3 -24 -18 -18 -8 4 -12 10 -9 15 6 11 18 12 27 3z m368
-7 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m-193 -18 c-2 -6
-8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m662 -9
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-190 -14 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z"/>
<path d="M4998 5093 c-10 -11 -18 -29 -18 -40 l0 -21 61 19 c33 10 84 19 112
20 l52 1 -34 14 c-18 8 -61 18 -95 21 -52 5 -64 3 -78 -14z"/>
<path d="M5240 5056 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M5357 5029 c-18 -10 -16 -14 19 -50 20 -21 42 -35 47 -32 6 3 7 1 3
-5 -3 -6 -1 -14 5 -18 6 -3 22 -30 36 -58 13 -28 22 -40 19 -26 -2 14 -8 42
-11 62 -4 21 -12 35 -17 32 -6 -4 -8 1 -4 14 5 14 -3 30 -27 56 -37 39 -43 41
-70 25z"/>
<path d="M5022 5019 l-33 -10 21 -29 c11 -16 46 -52 78 -80 l57 -51 21 25 c12
14 24 23 27 20 2 -3 14 15 25 38 33 70 4 96 -108 96 -30 0 -70 -4 -88 -9z"/>
<path d="M5311 4972 c-6 -11 -12 -29 -14 -39 -1 -10 -6 -24 -11 -31 -4 -7 -10
-37 -13 -67 -6 -52 -5 -55 21 -65 15 -5 48 -10 74 -10 77 0 89 44 37 135 -13
22 -37 53 -53 69 -30 27 -30 28 -41 8z"/>
<path d="M4828 4917 c-48 -44 -88 -104 -88 -133 0 -12 10 -30 22 -38 22 -16
23 -15 35 41 7 31 26 80 42 110 16 29 28 53 28 53 -1 0 -18 -15 -39 -33z"/>
<path d="M4902 4905 c-31 -26 -65 -87 -82 -144 -8 -30 -8 -31 23 -31 37 0 152
33 172 50 17 15 0 75 -34 119 -29 38 -41 38 -79 6z"/>
<path d="M5127 4745 c-38 -27 -5 -86 35 -64 23 13 23 51 0 65 -14 9 -22 9 -35
-1z"/>
<path d="M5380 4686 c-25 -7 -61 -20 -82 -29 -35 -15 -36 -16 -28 -52 11 -47
50 -105 72 -105 42 0 102 81 132 178 6 19 4 22 -21 21 -15 0 -48 -6 -73 -13z"/>
<path d="M5500 4675 c0 -32 -42 -141 -68 -175 l-26 -35 34 26 c73 55 124 144
103 178 -14 22 -43 26 -43 6z"/>
<path d="M4795 4660 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4866 4654 c-21 -20 -20 -40 4 -93 22 -47 77 -121 90 -121 16 0 50
88 56 147 l7 60 -33 12 c-47 16 -106 14 -124 -5z"/>
<path d="M4806 4575 c8 -63 22 -107 44 -143 24 -40 44 -48 76 -34 l24 12 -45
41 c-47 44 -81 91 -96 137 -7 22 -8 20 -3 -13z"/>
<path d="M5108 4543 c-14 -16 -34 -43 -43 -62 -17 -35 -17 -35 9 -58 23 -20
37 -23 99 -22 40 1 85 4 101 8 l29 6 -40 50 c-39 49 -103 105 -120 105 -5 0
-21 -12 -35 -27z"/>
<path d="M5365 4450 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M5245 4374 c-22 -8 -68 -15 -102 -16 -35 0 -63 -2 -63 -3 0 -9 113
-35 150 -35 54 0 80 15 80 45 0 28 -9 30 -65 9z"/>
<path d="M3878 3763 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3933 3763 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4003 3763 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M4614 3595 c0 -93 2 -133 3 -88 2 45 2 122 0 170 -1 48 -3 12 -3 -82z"/>
<path d="M4360 3376 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M4574 3358 l-19 -23 23 19 c12 11 22 21 22 23 0 8 -8 2 -26 -19z"/>
<path d="M4448 3323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4498 3323 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4988 3763 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4899 3733 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M4871 3414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5099 3353 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M4968 3323 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5443 3763 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M5418 3323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5473 3323 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4680 2605 c0 -24 5 -35 15 -35 10 0 15 11 15 35 0 24 -5 35 -15 35
-10 0 -15 -11 -15 -35z"/>
<path d="M5000 2619 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M5530 2620 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5
0 -10 -9 -10 -20z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
